/*
@File: Wipdata ChatBot Dashboard

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.


/*
Default Style
============================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
/* font-family: 'Inter', sans-serif; */

/* Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

:root {
  /**
  @font family declaration
  */
  --font-poppins: 'poppins';
  --font-montserrat: 'Inter', sans-serif;
  /**
  @color declaration
  */
  --brand-color: #009688;
  --brand-color-bold: #007268;
  --brand-color-light: #00968817;
  --secondary-color: #35a989;
  --paragraph-color: #7e7e7e;
  --paragraph-size: 17px;
  --orange-color: #fd346e;
  --heading-color: #292930;
  --gray-color: gray;
  --gray-color-2: #f8f8f8;
  --bg-color: #f2f4f8;
  --border-color: #12131447;
  --font-bold: 500;
  --white-color: white;
  --wip-color: #f92c01;
  --wip-bg-img-color: radial-gradient(
    circle at top right,
    #fda40c 0%,
    #f92c01 100%
  );
  --secondary-bg: linear-gradient(115deg, rgb(42, 39, 218), rgb(0, 204, 255));
  --purpleColor: #c211a1;
  --shadow: 0 1px 1px rgb(0 0 0 / 10%);
  --hover-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  --header-height: 65px;
  --radius: 4px;
  --font-color: #35a989;
  --green-color: #009688;
  --danger-color: hsl(11deg 100% 37.8% / 86%);
  --transition: all 0.3s ease-in-out;
  --sidebar-width: 5%;
  --sidebar-width-active: 18%;
  --sidebar-body-width: 95%;
  --sidebar-body-width-active: 82%;
  --green-color: #0566ff;
  --green-bg: #0566ff;
  --sidebar-icon-width: 68px;
  --blue-color: #35a989;

  --tiny-size: 12px;
  --p-size: 15px;
  --p-line-height: 27px;
  --p-line-height-mobile: 25px;
  --p-color: #000000;

  --mini-size: 14px;
  --mini-color: #000000d6;

  --heading-size: 30px;
  --heading-color: #000000;

  --title-size: 19px;
  --title-color: var(--black);

  --mini-title-size: 17px;
  --mini-title-color: var(--black);

  --light: 'lightgray';
  --fontBold: 600;
  --poppins: 'poppins';

  /* plate color orange */
  --orange-plate-bg: #e67e22;
  --blue-plate-bg: #2980b9;
  --red-plate-bg: #e74c3c;
  --border-color: #d3d3d369;

  --heading-one: 30px;
  --heading-two: 25px;
  --heading-three: 20px;
  --heading-four: 18px;
  --heading-five: 17px;
  --heading-six: 15px;
}

.dark-theme {
  --bg: #151521;
  --bg-light: #1e1e2d;
  --p-color: #565674;
  --p-light-color: #d7d7d7;
  --h-color: #ffffff;
  --gray: gray;
  --model-bg: #000000cc;
  --popup-bg: #ffffffba;
  --loading-dot-bg: #3d3d53;
  --shadowColor: #1e1e2d;
}
.light-theme {
  --bg: #f8f7fa;
  --bg-light: #ffffff;
  --p-color: #565674;
  --p-light-color: #607d8b;
  --h-color: #565674;
  --model-bg: #ffffffba;
  --popup-bg: #000000d1;
  --loading-dot-bg: #3d3d53;
  --shadowColor: lightgray;
}

/* html {
  scroll-behavior: smooth;
} */
/* html {
  overscroll-behavior: contain;
} */
.overscroll-off {
  overscroll-behavior: contain;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  color: var(--p-color);
  line-height: 26px;
  overflow-x: hidden;
  transition: var(--transition);
  overscroll-behavior: none;
  background: transparent !important;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-montserrat);
  color: var(--heading-color);
  margin-top: 0px;
  font-weight: var(--font-bold);
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: var(--title-size);
}

h3 {
  font-size: var(--title-size);
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: var(--font-poppins);
  font-size: var(--p-size);
  font-weight: 400;
  color: var(--paragraph-color);
}

.z-index {
  position: relative;
  z-index: 3;
}
.common-button {
  padding: 8px 12px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: #009688;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
}
.common-button span {
  display: flex;
  align-items: center;
  grid-gap: 3px;
}
.common-button svg {
  color: white;
}
.p-relative {
  position: relative !important;
}
.loading-btn {
  width: 110px;
  height: 35px;
  background: #c5c5c5;
  cursor: not-allowed !important;
}
a,
.btn,
button,
input,
select,
textarea,
li,
img,
svg,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  /* font-family: var(--font-montserrat); */
  font-family: var(--poppins);
}
input,
textarea,
select {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: var(--transition);
  outline: none;
  font-size: var(--mini-size);
  width: 100%;
  color: var(--heading-color);
  font-family: var(--poppins);
  background: var(--bg);
}
input:focus,
textarea:focus,
select:focus {
  border: 1px solid var(--brand-color) !important;
}
textarea {
  /* height: 150px; */
  line-height: 26px;
  resize: vertical;
}
li {
  list-style: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
.btn {
  width: max-content !important;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

svg {
  font-size: 22px;
  color: var(--gray-color);
  cursor: pointer;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
  font-family: var(--font-poppins);
}
button {
  transition: all 0.3s ease-in;
}
button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
  font-family: var(--font-poppins);
}

input[type='color'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

*::-moz-placeholder {
  color: rgb(189, 189, 189);
  font-size: 15px;
  opacity: 1;
  font-weight: 200;
}

*::placeholder {
  color: rgb(173, 173, 173);
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 600;
}
.text-danger {
  color: var(--danger-color) !important;
  font-weight: 400;
  font-size: 15px;
}
.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}
/* Dashboard / Home screen Style
================================ */
section.main-section {
  width: 100%;
}
.dashboard-wrapper {
  width: 100%;
  background: var(--bg-light);
}
aside.sidebar {
  width: 100%;
  background: var(--bg-light);
  height: 100%;
}

aside.sidebar ul {
  display: flex;
  flex-direction: column;
}

aside.sidebar ul li {
}
.dropdown-icon {
  margin-right: 0px;
}

aside.sidebar ul li a {
  font-size: 16px;
  color: black;
  font-weight: 400;
  padding: 10px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
}
.grouped-item {
  border-bottom: 1.8px solid #d3d3d32e;
  padding-top: 15px;
}
.grouped-item:last-child {
  border-bottom: unset;
}
.sub-heading {
  text-transform: uppercase;
  font-weight: 500;
  color: black;
  font-size: 14px;
}
.flex-label-icon {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: center;
  font-weight: 500;
}

.flex-label-icon svg {
  width: 20px;
}
.active-link svg,
.active-link span {
  color: var(--brand-color);
}
/* .active-link::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 5px;
  background: #03a9f4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
} */
aside.sidebar ul li svg {
  font-size: 25px;
}
main {
  width: 100%;
  box-sizing: border-box;
  transition: var(--transition);
}

/* Header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
  position: sticky;
  top: 0px;
  z-index: 999;
  height: var(--header-height);
}
header.plain-header {
  padding: 0px 50px;
}
header.active {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  z-index: 999;
  transition: var(--transition);
}

.header-logo img {
  width: 100%;
  object-fit: contain;
}
.header-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 40px;
}
.search-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-box input {
  height: 40px;
  border: 1px solid var(--bg);
  background: var(--bg);
  width: 400px;
  padding-left: 40px;
  border-radius: 5px;
}
.search-box svg {
  position: absolute;
  left: 10px;
}
.burger-menu-icon {
  font-size: 30px;
  cursor: pointer;
}
.header-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  position: relative;
}
.header-profile p {
  font-size: var(--p-size);
  line-height: 22px;
  color: var(--p-light-color);
  font-weight: 500;
}
.header-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.header-profile svg {
  color: var(--gray-color);
}
.body-container {
  width: 100%;
  min-height: 90vh;
}
.body-wrapper {
  /* padding: 30px; */
  width: 100%;
  transition: var(--transition);
}

/* .container {
  max-width: 1250px;
  margin: auto;
} */

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3px;
  color: var(--gray-color);
  font-size: 16px;
  margin-bottom: 20px;
}
.breadcrumb li {
  font-size: 13px;
}
.breadcrumb a {
}
.breadcrumb a:hover {
  color: var(--brand-color);
}

/* breadcrumbs-and-filter */
.breadcrumbs-and-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  display: none;
}
.blank {
  display: none;
}

.date-filter {
  background: var(--bg-light);
  padding: 7px 20px;
  border-radius: 30px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--gray-color);
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.date-filter h6 {
  color: lightslategrey;
  font-weight: 500;
}
.date-filter svg {
  font-size: 20px;
}

.header-section {
  background: var(--bg-light);
  box-shadow: var(--shadow);
  margin-bottom: 35px;
}
.header-logo-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  text-transform: capitalize;
}
.header-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: var(--hover-shadow);
}
.header-logo-content {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.header-logo-content svg {
  font-size: 17px;
}
.header-logo-content p,
.header-logo-content svg {
  color: var(--brand-color);
}
.role-badge {
  background: #00968917;
  color: #009686 !important;
  font-weight: 500;
  font-size: 11px !important;
  padding: 0px 5px;
  border-radius: 30px;
  text-align: center;
  width: max-content;
  line-height: 17px !important;
}

/* featured item */
.featured-status-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 20px;
  margin-top: 10px;
}
.featured-status-item {
  background: var(--bg-light);
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--hover-shadow);
  width: 100%;
  transition: 0.3s ease-in-out;
  min-height: 158px;
  cursor: pointer;
  border: 3px solid transparent;
}
.react-tabs {
  width: 100%;
}
.featured-status-item.react-tabs__tab--selected {
  border: 3px solid white;
}

.featured-status-item:hover {
  box-shadow: var(--shadow);
}

/* for desktop */
.featured-status-item:nth-child(1) {
  background: #2155cd;
}
.featured-status-item:nth-child(2) {
  background: #9c27b0;
}
.featured-status-item:nth-child(3) {
  background: #009688;
}

.featured-status-item:nth-child(4) {
  background: #683ab72c;
}
.featured-status-item:nth-child(5) {
  background: #683ab79c;
}
.featured-status-item:nth-child(6) {
  background: #673ab7 !important;
}

.featured-status-content h3,
.featured-status-content h1 {
  color: white;
}
.featured-bottom-content svg,
.featured-bottom-content span {
  color: white;
}

/* for special */

.featured-top-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.featured-status-content {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.featured-status-content h3 {
  font-size: 17px;
  font-weight: 500;
}
.featured-status-content h1 {
}
.featured-status-thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: var(--hover-shadow);
}
.featured-status-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.featured-bottom-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--paragraph-color);
}

.percent-of-up,
.percent-of-down {
  background: green;
  border-radius: 30px;
  padding: 0px 10px;
  color: white;
}
.percent-of-down {
  background: var(--wip-color);
}

.up-icon {
  color: green;
}
.down-icon {
  color: var(--wip-color);
}

/* dropdown menu */
.header-profile-dropdown {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 200px;
  background: var(--bg-light);
  box-shadow: var(--hover-shadow);
  transition: var(--transition);
  border-radius: 3px;
  padding: 13px;
  visibility: hidden;
  opacity: 0;
}
.header-profile-dropdown.active {
  visibility: visible;
  opacity: 1;
  top: 52px;
  z-index: 999;
}
.header-profile-dropdown ul li {
  width: 100%;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #d3d3d326;
  color: var(--gray-color);
}
.header-profile-dropdown ul li:last-child {
  border-bottom: unset;
}
.header-profile-dropdown ul li:hover {
  background: var(--bg-light);
  border-radius: 5px;
}
.header-profile-dropdown ul li a {
  display: flex;
  justify-content: flex-start;
  grid-gap: 7px;
  align-items: center;
}
.header-profile-dropdown ul li a svg {
  font-size: var(--title-size);
}

/* chart */
.chart-wrapper {
  width: 100%;
  grid-gap: 20px;
  margin-bottom: 15px;

  display: flex;
  justify-content: space-between;
}
.chart-item {
  background: var(--bg-light);
  width: 100%;
  /* padding: 15px; */
  box-shadow: var(--hover-shadow);
  border-radius: 5px;
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; */
  padding-bottom: 5px;
  position: relative;
  padding-bottom: 70px;
  border: 1px solid var(--border-color);
}
.chart-item svg {
  font-size: 12px;
}

/* chart header */
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.chart-header h3 {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-color);
}
.chart-header-option svg {
  font-size: 25px;
}

.chart-svg-wrapper {
  width: 100%;
  height: 250px;
  padding: 0px 40px;
}
.mobile-sidebar-cross {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 33px;
  color: red;
  cursor: pointer;
  z-index: 9999;
}

/* Admin screen */
.admin-page-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
}

/* admin header */
.admin-header {
  background: var(--bg-light);
  padding: 20px 0px;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  background-color: #fff;
  background-color: var(--bs-sidebar-bg);
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  display: flex;
  height: 100vh;
  max-width: 75px;
  min-height: 570px;
  min-width: 75px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.admin-header ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
}

.admin-header ul li {
}
.admin-header ul li img {
  width: 30px;
}
.admin-header ul li a {
}

.admin-header ul li svg {
  font-size: 23px;
  color: var(--gray-color);
}

.admin-header ul li svg:hover {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  color: #7269ef;
}
.admin-header svg.active {
  color: #7269ef;
}
/* admin chat body */
.chat-body-wrapper {
  display: grid;
  grid-template-columns: 3fr 6fr;
  width: 100%;
  height: 100%;
  transition: var(--transition);
  position: relative;
}
.chat-body-wrapper.active {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 9999999999999;
  background: var(--bg-light);
}
.chat-body-sidebar {
  padding: 20px;
  /* border: 1px solid lightgray;
  border-top: unset;
  border-bottom: unset; */
  /* min-width: 400px;
  max-width: 400px; */
  background: var(--bg);
  height: 100%;
  margin-top: 1px;
  padding-bottom: unset;
  min-width: 370px;
}
.chatbox-sidebar-wrap {
  overflow: hidden;
}
.chat-body-search {
  position: relative;
  width: 100%;
}
.chat-body-search input {
  padding: 10px;
  font-size: 15px;
  border-radius: 8px;
  border: unset;
  width: 100%;
  outline: none;
  background: var(--bg-light);
  font-family: poppins;
  padding-left: 10px;
  border: 1px solid lightgray;
}
.chat-body-search svg {
}
.featured-chats {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
}
.featured-chat-item {
  background: var(--bg-light);
  border-radius: 5px;
  padding: 4px 13px;
}
.featured-chat-item p {
  color: var(--gray-color);
  font-size: 12px;
  text-align: center;
  margin-top: 3px;
}
.featured-chat-thumb {
  width: 50px;
  height: 50px;
  position: relative;
}
.featured-chat-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.chatbox {
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  flex-direction: column; */
}
.chat-message-body {
  width: 100%;
}
.chatbox-wrapper {
  width: 100%;
  position: relative;
}
.audio-hidden {
  display: none;
}

/* recent chat */
.chat-title {
  margin: 8px 0px;
  color: var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.add-new-group-icon {
  background: var(--bg-light);
  border-radius: 50%;
  font-size: 30px;
  padding: 5px;
  box-shadow: var(--hover-shadow);
}
.create-group-wrapper {
  margin-top: 10px;
}
.create-group-btn {
  background: var(--brand-color);
  padding: 10px 20px;
  border-radius: var(--radius);
  color: white;
  font-size: 16px;
  width: fit-content;
  margin-top: 7px;
}
.chat-title:first-child {
  margin-top: unset;
  margin-bottom: 0px;
}
.chat-title:last-child {
  margin-bottom: 10px !important;
}
.chat-title h2 {
  font-weight: 500;
  font-size: 15px;
  font-family: montserrat;
  color: var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.chat-title-big h2 {
  font-size: var(--title-size);
  font-weight: 700;
  color: var(--gray);
}

.recent-chat {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  transition: all 0.3s ease 0s;
  max-height: 65vh;
  scrollbar-width: thin;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.pinned-recent-chat {
  height: 180px;
}
/* recent chat scrollbar width */
.recent-chat::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.recent-chat::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.recent-chat::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.recent-chat::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.recent-chat-wrap {
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}
.recent-chat-item {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  transition: background 0.3s ease-in;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 0px 0px 4px 0px lightblue;
  width: 100%;
}
.recent-chat-item.active {
  border: 2px solid var(--brand-color);
}
.recent-chat-item.active .recent-chatbox-content h2 {
  color: var(--blue-color) !important;
}
.recent-chat-item.active .recent-chatbox-user-chat svg,
.recent-chat-item.active .recent-chatbox-user-chat span,
.recent-chat-item.active .recent-chat-item-inner p,
.recent-chat-item.active .channel-right svg {
  color: var(--p-light-color) !important;
}
.recent-chat-item:hover {
  background: #cedeff;
}

.recent-chat-item:hover .channel-right {
  background: #cedeff;
  visibility: visible;
  opacity: 1;
}

.recent-chat-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
}
.recent-chat-item-inner.skeleton {
  display: unset;
}
.recent-chat-item-inner p {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chat-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
}
.recent-chat-item-content.skeleton {
  display: unset;
}
.recent-chatbox-content {
}
.recent-chatbox-content h2 {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-color);
}
.recent-chatbox-content p {
  font-size: 13px;
  color: #7a7f9a;
}
.recent-chatbox-user-chat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.recent-chatbox-user-chat svg {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chatbox-user-chat span {
  color: #7a7f9a;
  font-size: 12px;
}
.typing-item-wrap {
  margin-top: 5px;
}
.typing-wrap {
  padding: 9px 13px !important;
}
.typing-wrap .typing {
  color: #000 !important;
}
.typing-in-chat {
  color: #7269ef !important;
  font-weight: 500;
}
.typing-in-chat .animate-typing .dot {
  background: #7269ef !important;
  margin-right: 3px;
}

/* call model */
.call-model-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(8, 8, 8, 0.507);
  transition: var(--transition);
  padding: 40px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  top: -50px;
}
.call-model-container.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
}
.call-model-wrapper {
  background: var(--bg-light);
  border-radius: 8px;
  width: 450px;
  height: 300px;
  margin: auto;
  box-shadow: var(--brand-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
}
.call-user-avatar {
  width: 100px;
  height: 100px;
}
.call-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #d3d3d342;
}
.call-user-content {
  text-align: center;
}
.call-user-content h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--blackColor);
}
.call-user-content p {
  color: var(--paragraphColor);
  font-size: 14px;
}
.call-user-actions {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}
.call-user-icon {
  background: #06d6a0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.call-user-icon svg {
  color: white;
  font-size: 23px;
}
.call-user-icon:first-child {
  background: red;
}
/* search modal */
.search-model {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 30px;
  transition: var(--transition);
  bottom: 0px;
  right: 150px;
  z-index: 999;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
}
.search-model.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
}
.search-model input {
  border: unset;
  background: var(--bg-light);
  border-radius: 8px;
  padding: 10px 15px;
  width: 350px;
  font-family: poppins;
  /* font-size: 16px; */
  padding-right: 35px;
  padding-left: 35px;
  outline: none;
}
.search-model svg {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--gray-color) !important;
  cursor: pointer;
  font-size: 13px;
}
svg.search-model-search-icon {
  position: absolute;
  left: 10px;
  top: 0px;
  color: #7a7f9a;
  height: 100%;
  font-size: var(--title-size);
}

/* dropdown menu */
.dropdown-menu-wrapper {
  position: absolute;
  transition: var(--transition);
  top: 40px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
}
.dropdown-menu-wrapper.active {
  visibility: visible;
  opacity: 1;
  top: 60px;
}
.dropdown-menu-wrapper.active-chat-item {
  visibility: visible;
  opacity: 1;
  top: 30px;
}
.chatbot-dropdown.active {
  top: 75px;
}
svg.cross-chatbot-dropdown {
  width: 25px;
}
.dropdown-menu-wrapper ul {
  background: var(--bg-light);
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0px 1px 3px 0px lightgrey;
  width: 112px;
  height: 100%;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.dropdown-menu-wrapper ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-color);
  grid-gap: 10px;
  margin-bottom: 5px;
  padding: 0px 7px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 15px;
}
.dropdown-menu-wrapper ul li:hover {
  color: #7269ef;
}
.dropdown-menu-wrapper ul li svg {
  font-size: var(--title-size);
}
.dropdown-menu-wrapper ul li:last-child {
  margin-bottom: unset;
}

/* admin dashboard */
.chatbox-recipient-avatar img {
  box-shadow: unset;
}
.chatbot-widget-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.chatbot-widget-recipient-details {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-top: -7px; */
}
.chatbot-widget-recipient-details p {
  font-weight: 300;
  font-size: 12px;
  color: white;
}
.chatbot-widget-recipient-details h3 {
  font-weight: 600;
  line-height: 10px;
  font-size: 13px;
  color: white;
}
.chatbot-widget-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  flex-direction: column;
  position: relative;
}
.chatbot-widget-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 3px;
}

.visitor-email {
  padding: unset !important;
  width: 100% !important;
  background: unset !important;
}

/* admin dashboard */
.chatbox-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
}
.chatbox-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbox-header-icon img {
  width: 14px;
  filter: brightness(0.5);
  cursor: pointer;
  transition: var(--transition);
}
.chatbox-header-icon svg {
  /* fill: var(--gray-color); */
  color: var(--gray-color);
  font-size: 20px;
  cursor: pointer;
  transition: var(--transition);
}

/* site cradit */
.chatbot-creator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-creator p {
  font-size: 11px;
  color: white;
}
.chatbot-creator img {
  width: 18px;
  border-radius: 3px;
  object-fit: contain;
}
.chatbot-widget-options,
.chatbot-widget-minimize {
  fill: white;
  border-radius: 50%;
  font-size: 25px;
  position: relative;
  cursor: pointer;
}
.chatbot-widget-options svg,
.chatbot-widget-minimize svg {
  fill: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  transition: var(--transition);
}

.chatbot-widget-minimize svg {
}

.chatbot-widget-options svg:hover,
.chatbot-widget-minimize svg:hover {
  background: rgba(0, 36, 92, 0.16);
  border-radius: 50%;
}

/* chatbot body */
.chat-conversation-wrapper {
  padding: 0px 15px;
  width: 100%;
  overflow: hidden auto;
  background: var(--bg-light);
  transition: all 0.3s ease 0s;
  flex: 0 1 auto;
  scrollbar-width: thin;
  position: relative;
  /* max-height: 390px;
  min-height: 160px;
   */
  height: 78vh;
}
.chatbox-conversation {
  /* max-height: 75vh;
  min-height: 420px;
  height: 100%; */
  border-left: 2px solid var(--bg-light);
}
.chatbox-conversation-form {
  /* position: fixed;
  bottom: 0px;
  width: 618px; */
  z-index: 999;
  background: var(--bg-light);
}
/* width */
.chat-conversation-wrapper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.chat-conversation-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-conversation-wrapper::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
.chat-conversation-wrapper::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.message-body {
  position: relative;
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 30px; */
  float: left;
}
.message-body li {
  margin: 0 0px 5px 0px;
  overflow-wrap: break-word;
  display: inline-block;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-bottom: 10px; */
  min-width: 70px;
  max-width: 90%;
}
.chat-bubble-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: -webkit-fill-available;
}
.chat-bubble-wrap div {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
/* message agent */
.message-bubble-agent {
  color: #000;
  background: var(--bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: 280px;
  width: fit-content;
  font-size: 14px !important;
  line-height: 22px;
  padding: 8px 8px;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  text-align: left;
  /* max-width: 85%; */
}
.message-bubble-agent a {
  color: var(--secondary-color);
  font-weight: 500;
}
/* message visitor */
.message-bubble-visitor {
  width: fit-content;
  max-width: 90%;
  float: right;
  background: var(--brand-color);
  color: white;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  line-height: 23px;
  margin: 10px 0px !important;
  padding: 8px 14px;
  margin-bottom: 10px;
}
.message-bubble-visitor span {
  /* text-transform: capitalize; */
}
.agent-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: contain;
  padding: 2px;
  box-shadow: 0px 0px 4px 0px lightgray;
}
.message-operator .messageTimestamp {
  height: 23px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  top: calc(100% + 4px);
  left: 12px;
}
.messageTimestamp,
.bot-date-response-badge {
  bottom: -20px;
  font-size: 9px;
  color: rgb(136, 148, 171);
  position: absolute;
  transition: all 0.2s ease 0s;
  white-space: nowrap;
  right: 0px;
}
.bot-date-response-badge {
  left: 0px;
}
/* message babble */
.animate-typing .dot {
  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
  background-color: #7269ef;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-right: -1px;
  opacity: 0.6;
  width: 4px;
  margin-right: 5px;
}
.animate-typing .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.animate-typing .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  margin-right: 0px;
}

@-webkit-keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

/* chatbot footer */
.chatbot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  min-height: 70px;
  grid-gap: 10px;
  position: relative;
  border-top: 2px solid #f7f7f7;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  background-color: var(--white-color);
  z-index: 99;
}
.chatbot-footer form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  grid-gap: 5px;
  padding-bottom: 8px;
}
.relative {
  position: relative;
}
/* for admin dashboard */
.chatbot-footer-input-box {
  width: 100%;
  height: 40px;
  position: relative;
}
.chatbot-footer-input-box input {
  padding: 5px;
  border: 1px solid #80808033;
  border-radius: 7px;
  height: 40px;
  width: 100%;
  padding-left: 20px;
  font-family: poppins;
  background: var(--bg-light);
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.demo-message-agent {
  text-align: left;
}
.chatbot-footer-input-box input:focus {
  outline: none;
  border: 1px solid var(--brand-color);
}
.chatbot-send-message-button {
  border: unset;
  background: var(--brand-color);
  color: white;
  border-radius: 7px;
  font-size: 17px;
  position: absolute;
  right: 11px;
  top: 3px;
  bottom: 5px;
  padding: 0px;
  width: 70px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  grid-gap: 7px;
  text-align: center;
  overflow: hidden;
}
.send-msg-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  grid-gap: 6px;
}
.chatbot-send-message-button svg {
  color: white;
  font-size: 16px;
}

/* day slot */
.message-day-slot {
  padding: unset !important;
  margin-top: 10px !important;
}
.message-day-slot-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
  flex-flow: row wrap;
}
.message-day-slot-item {
  background: var(--bg-light);
  border: 1px solid var(--secondary-color);
  padding: 4px 10px !important;
  font-size: 12px !important;
  color: var(--secondary-color);
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition) !important;
}
.message-day-slot-item.active {
  background: var(--secondary-color);
  color: white;
}
.message-day-slot-item:hover {
  background: var(--secondary-color);
  color: white;
  transform: translateY(-2px);
}

/* suggest button */
/* .message-auto-suggest-buttons {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  margin: 10px 0px;
  margin-bottom: 5px;
  padding: 13px;
  width: 370px;
  overflow-x: auto;
} */
.message-auto-suggest-buttons {
  display: flex;
  justify-content: flex-start;
  /* flex-flow: wrap; */
  grid-gap: 5px;
  margin: 10px 0px;
  margin-bottom: 5px;
  flex-flow: wrap;
  padding: 13px;
  width: 370px;
  overflow-x: auto;
  position: absolute;
  bottom: 60px;
}
.chatbot-footer-container {
}
button.suggest-btn {
  border-radius: 4px;
  padding: 9px 13px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  border: 1px solid var(--green-color);
  align-items: center;
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 13px;
  background: var(--bg-light);
  color: var(--green-color);
  transition: all 0.3s ease-in-out;
  text-align: left;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.suggest-btn:disabled {
  background: #80808061;
  opacity: 0.5;
  cursor: not-allowed;
  color: black;
  border: 1px solid gray;
}

button.suggest-btn img {
}
button.suggest-btn span {
}
.clear-style-message {
  padding: unset !important;
  background: unset !important;
  box-shadow: unset;
}
button.suggest-btn:hover {
  background: var(--green-bg);
  color: white;
}
/* app progress */
.app-progress-section {
  padding: 70px 0px;
}
.app-progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 60px;
}
.app-progress-thumb {
  width: 80%;
  height: auto;
}
.app-progress-thumb img {
  width: 100%;
  height: 100%;
}
.app-progress-content {
  width: 100%;
}
.app-progress-content .sub-title {
  color: var(--blueDarkColor);
  font-weight: 500;
}
.app-progress-content h2 {
  font-family: montserrat;
  font-size: 40px;
  margin: 15px 0px;
}
.app-progress-content p {
  margin-bottom: 10px;
  font-size: 17px;
  color: var(--paragraphColor);
}
button.app-progress-button {
  background: var(--blueDarkColor);
  padding: 13px 23px;
  border-radius: 30px;
  color: white;
  font-size: var(--title-size);
  font-weight: 500;
  margin-top: 20px;
  transition: var(--transition) 0s;
  box-shadow: rgb(0 0 0 / 31%) 0px 1px 5px 0px;
  font-family: 'Montserrat';
}
button.app-progress-button:hover {
  transform: translateY(-3px);
}
.partner-section {
  padding: 50px 0px;
}
.partner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}
.partner-item {
  width: 100%;
  height: auto;
  padding: 20px;
  cursor: pointer;
  transition: var(--transition);
}
.partner-item:hover,
.what-we-offer-item:hover {
  box-shadow: 0px 1px 20px 0px #d3d3d375;
  border-radius: 10px;
  transform: translateY(-3px);
}
.partner-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* product carousel */
.message-product-carousel {
  padding: unset !important;
}
.product-carousel-wrapper {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin: 10px 0px;
  width: 135%;
}
.chat-product-carousel-item {
  background: var(--bg-light);
  border-radius: 10px;
  overflow: hidden;
  width: 200px !important;
  height: fit-content;
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  transition: var(--transition) !important;
  min-height: 165px;
}
.chat-product-carousel-item:hover {
  transform: translateY(-2px);
}
.chat-product-carousel-thumb {
  width: 100%;
  height: 120px;
  margin-bottom: 7px;
}
.chat-product-carousel-thumb img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
.chat-product-details {
  padding: 3px 5px;
}
.chat-product-details h2 {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}
.chat-product-details p {
  font-size: 12px;
  line-height: 19px;
}
.chat-product-order-button {
  border: unset;
  background: var(--secondary-bg);
  color: white;
  margin-top: 5px;
  width: 100%;
  padding: 7px 10px;
  font-family: poppins;
}
.chatbot-footer-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-footer-options svg {
  color: #1189f0;
  font-size: 20px;
}

/* carusaul */
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 95px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px;
  background: var(--bg-light);
  padding: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset;
  color: var(--font-color);
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.1;
}
.featured-thumb {
  width: 100%;
  height: 100%;
  background-color: #1189f0;
}
.featured-thumb img {
  width: 100%;
  height: 100%;
}

/* chatbot */
.chatbot-widget {
  background: var(--bg-light);
  background-color: transparent;
  border-radius: 8px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  box-shadow: 0px 1px 3px 0px lightgray;
  height: 0px;
  width: 372px;
  overflow: hidden;
  /* overflow: auto; */
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: relative;
  z-index: -1;
  overscroll-behavior: contain;
  /* display: none; */
  border-radius: 10px;
}
.chatbot-widget.active {
  visibility: visible;
  opacity: 1;
  height: 475px;
  z-index: 999;
  display: block;
  overflow: unset;
}
.chatbot-widget.active-iframe {
  visibility: visible;
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: block;
  overflow: unset;
  /* border: 1px solid #009688; */
  border-radius: 10px;
  background: var(--white-color);
}

.chatbot-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--secondary-bg);
  padding: 8px 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chatlogs-header {
  padding: 15px;
}
/* for admin dashboard message header */
.chatbox-header {
  background: var(--bg-light);
  position: relative;
  transition: var(--transition);
  border: 1px solid #d3d3d354;
  border-bottom: 2px solid #d3d3d354;
  border-radius: unset;
  position: sticky;
  top: 0px;
  z-index: 99;
}
.chatbox-header p,
.chatbox-header h3 {
  color: var(--p-light-color);
  text-transform: capitalize;
}

.chatbot-widget-agent-precence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chatbot-iframe-precence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chatbot-recipient-avatar {
  position: relative;
}
.online-status {
  position: absolute;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: var(--blue-color);
  border: 2px solid white;
  top: 0px;
  right: 0px;
}
.chatbot-recipient-avatar {
  width: 45px !important;
  height: 45px !important;
}
.chatbot-recipient-avatar img {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  object-fit: contain;
  background: var(--bg-light);
  box-shadow: var(--brand-shadow);
  border: 2px solid white;
}

/* 404 not found */
.not-found-header {
  width: 100%;
}

.not-found-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%; */
}
.not-found-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 60%;
  margin: auto;
  margin-top: 40px;
}
.not-found-content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.not-found-content h1 {
  font-size: 250px;
  line-height: 240px;
  color: var(--heading-color);
  text-shadow: 6px 6px 1px #ffffff;
}
.not-found-content h2 {
  font-family: var(--font-montserrat);
}
.not-found-content p {
}
.not-found-wrapper button a {
  background: var(--brand-color);
  color: white;
  padding: 12px 25px;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: max-content;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
  box-shadow: var(--shadow);
  transition: var(--transition);
}
.not-found-wrapper button:hover {
  transform: translateY(-3px);
}
.not-found-wrapper button svg {
  color: white;
}

/* integration screen */
.integrate-container {
  padding: 20px;
}
.integrate-header {
  background: var(--bg-light);
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  border-left: 3px solid lightgray;
}
.integrate-header h3 {
  font-size: var(--title-size);
  color: var(--gray-color);
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.integrate-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 15px;
}
.integrate-item {
  background: var(--bg-light);
  padding: 20px 20px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
}
/* .integrate-item.bot-demo-item::after {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  width: 200px;
  height: 200px;
  background: #00b47d;
  z-index: -1;
  -webkit-clip-path: polygon(94% 93%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(94% 93%, 100% 0%, 100% 100%, 0% 100%);
} */
.integrate-item.bot-demo-item h2 {
  margin-bottom: 5px;
}
.integrate-item.bot-demo-item p {
  line-height: 22px;
}
.integrate-item h2 {
  font-size: 19px;
  font-family: var(--font-montserrat);
  margin-bottom: 10px;
  color: var(--heading-color);
}
.integrate-item p {
  font-size: 15px;
  line-height: 25px;
}
.integrate-icon {
  width: 60px;
  height: auto;
}
.integrate-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.integrate-content {
}
.text-messenger {
  color: #446cff;
}
.text-instagram {
  color: #f52670;
}
.text-whatsapp {
  color: #2db842;
}
.text-telegram {
  color: #5c9ced;
}
.text-email {
  color: #00b2d0;
}
.text-twitter {
  color: #1d83ff;
}

/* Login and Register */
.register-section {
  background: var(--bg);
  padding: 50px 0px;
}
.register-container {
  width: 100%;
  z-index: 99;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  border-radius: 10px;
  box-shadow: var(--hover-shadow);
  margin: 0px auto !important;
  background: var(--white-color);
}
.password-model {
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}
.password-model.active {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.register-wrapper {
}
.register-wrapper form {
  height: 100%;
  margin: auto;
  background: var(--bg-light);
  padding: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.form-row {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
}
.form-group {
  width: 100%;
  margin-bottom: 10px;
}
.form-group:last-child {
  margin-bottom: 0px;
}
.form-group label {
  color: var(--gray-color);
  font-size: var(--tiny-size);
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.form-group-input-icon {
  position: relative;
}
.form-group-input-icon input {
  position: relative;
}
.form-group-input-icon svg {
  position: absolute;
  right: 15px;
  top: 10px;
}
/* .form-group input, .form-group select{
  height: 50px;
} */

.form-group select {
}
.form-button {
  width: max-content;
  background: var(--brand-color);
  padding: 0px 15px;
  border-radius: 5px;
  box-shadow: var(--shadow);
  color: white;
  font-size: var(--mini-size);
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  font-weight: 500;
  border: 1px solid var(--brand-color);
  height: 42px;
  /* position: relative; */
  /* margin-top: 20px; */
}
.all-bot-action-btn {
  margin-top: 0px;
  padding: 0px 15px;
  border: 2px solid transparent;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  font-size: var(--p-size);
  border-radius: 5px;
  background: var(--brand-color);
  color: var(--white-color);
}
.all-bot-action-btn span {
  font-size: var(--mini-size);
}
.all-bot-action-btn.button-outline {
  border: 2px solid var(--brand-color);
}

.all-bot-action-btn.button-outline svg {
  color: var(--brand-color) !important;
}
.all-bot-action-btn svg {
  font-size: 20px;
  color: var(--white-color);
}

.--bg {
  background: var(--bg) !important;
}
.form-button:hover {
  /* transform: translateY(-1px); */
}
.form-button.disabled {
  background: gray;
  cursor: not-allowed;
  border: 1px solid transparent;
}
.already-have-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 6px;
}
.already-have-account p {
  font-family: var(--font-montserrat);
  font-size: 13px;
}
.link {
  font-weight: 400;
  color: var(--brand-color);
}
.form-title {
  font-family: var(--font-montserrat);
  margin-bottom: 20px;
}
.form-title svg {
  color: var(--brand-color);
}
.form-title h2 {
  color: var(--font-color);
  font-weight: 600;
  font-size: 22px;
  /* margin-bottom: 10px; */
}
.form-title h3 {
  color: var(--h-color);
  font-size: var(--title-size);
}
.form-title p {
  font-size: var(--p-size);
  font-weight: 400;
}
.form-title p strong {
  font-weight: 500;
  color: var(--h-color);
}
.form-header-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--bg);
}
.single-logo-wrapper {
}
.single-logo {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.single-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.096));
}
.logo-text {
}
.logo-text h2 {
  font-size: 20px;
  color: var(--wip-color);
  font-family: var(--font-poppins);
}
.forgot-heading {
  margin-bottom: 10px;
}

/* table */

/* product screen */
table {
  font-family: var(--font-poppins);
  border-collapse: collapse;
  width: 100%;
  /* background: var(--bg); */
  /* border: 2px solid red; */
}
tr {
  /* color: #2f4f4fc9; */
  border: 1px solid #6d6d6d4f;
  border-top: 1px;
  border-right: 1px;
  border-left: 1px;
}
.header-upper {
  /* text-transform: uppercase; */
}
.th-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* grid-gap: 5px; */
}
.data-table-body tr:first-child {
  font-weight: 600;
  text-transform: capitalize;
}
td,
th {
  /* border: 1px solid #dddddd4f; */
  /* border: 1px solid #6b6b6b4f; */
  text-align: left;
  padding: 8px;
  /* color: var(--p-light-color); */
  color: black;
  font-size: 14px;
  word-wrap: break-word;
  max-width: 300px;
}

/* tr:nth-child(even) {
  background-color: #dddddd1f;
} */
.table-container {
  padding: 20px;
  border: 1px solid #6d6d6d4f;
  border-radius: 8px;
  position: relative;
  /* background: var(--bg); */
  background: #fbfbfb;
  /* height: 75vh; */
  overflow: hidden;
}
.table-wrapper {
  width: 100%;
  background: var(--bg) !important;
  /* padding: 10px; */
  overflow: auto;
  height: 75vh;
  /* color: black !important; */
}

/* width */
.table-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.table-wrapper::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #f1f1f1;
}

/* Handle */
.table-wrapper::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.178);
  border-radius: 30px;
}

/* Handle on hover */
.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-wrapper table {
  width: 100%;
  padding: 5px;
}
.data-table-body tr {
  font-weight: 500;
}
.data-table-body tr:first-child {
  font-weight: 700 !important;
}

.table-wrapper tbody tr td img {
  width: 110px;
  height: 65px;
  object-fit: cover;
  border-radius: 3px;
}

tbody {
  /* height: 40vh; */
  overflow: auto;
}
.action-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  grid-gap: 10px;
}
.action-wrapper .edit-icon {
  color: var(--gray-color);
}
.action-wrapper .delete-icon {
  color: var(--danger-color);
}
.badge {
  color: var(--white-color);
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 10px;
  text-transform: capitalize;
  box-shadow: var(--shadow);
  cursor: pointer;
}
.marketplace-status {
  color: var(--brand-color);
  border-radius: 40px;
  font-size: var(--mii-size);
  padding: 3px 18px;
  text-transform: capitalize;
  box-shadow: var(--shadow);
  cursor: pointer;
  background: #0096881f;
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 500;
}
.admin-badge {
  background: var(--brand-color);
}
.editor-badge {
  background: var(--green-color);
}
.table-name {
  font-weight: normal;
}
.user-badge {
  background: var(--paragraph-color);
}
.department-badge {
  color: green;
  background: rgba(0, 128, 0, 0.13);
  font-weight: 500;
}

/* table header */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* background: var(--bg); */
  border-radius: var(--radius);
  margin-bottom: 20px;
}
.table-title-wrap {
  flex-direction: column;
  grid-gap: 4px;
}
.table-title {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
}

.table-title h3 {
  font-size: var(--title-size);
  color: black;
}
.table-title p {
  color: #667085;
  font-size: var(--p-size);
}

.table-add-new {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
}
.table-add-new img {
  width: 20px;
}
.table-add-new .table-header-button {
  padding: 8px 9px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--brand-color);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  min-height: 33px;
  min-width: 100px;
}
.table-add-new .table-header-button span {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
}

/* pagination */
.pagination-wrapper {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}
.pagination {
  display: inline-block;
  display: flex;
  justify-content: flex-end;
}

.pagination a {
  color: var(--paragraph-color);
  float: left;
  padding: 0px 10px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: var(--brand-color);
  color: white;
  border: 1px solid var(--brand-color);
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination a:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* create user model */
.create-user-wrapper {
  position: fixed;
  inset: 0;
  top: -100px;
  background: var(--popup-bg);
  display: flex;
  justify-content: center;
  transition: var(--transition);
  align-items: center;
  visibility: hidden;
  opacity: 0;
}
.create-user-wrapper.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
  z-index: 999;
}
.create-user-form {
  width: 500px;
  height: max-content;
  margin: auto;
  background: var(--bg-light);
  padding: 30px;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  position: relative;
}
.time-icon {
  color: var(--danger-color);
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* table filter */
.table-filter-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: var(--bg-light);
  border-radius: 3px;
}
.table-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.table-filter .form-group {
  margin-bottom: unset;
}
.table-filter .form-group input,
.table-filter .form-group select {
  padding: 6px 10px;
  font-size: 14px;
  border: unset;
  /* background: #f2f4f880; */
  transition: var(--transition);
  border: 1px solid var(--border-color);
  width: auto;
}
.ant-input {
  padding: 10px;
  font-size: var(--mini-size);
  height: unset;
  font-family: var(--poppins);
}
.ant-input:hover {
  border: 1px solid lightgray !important;
}
.ant-form-item-required {
  font-family: var(--poppins);
  font-size: var(--mini-size) !important;
  padding-bottom: 0px !important;
}
.ant-form-item {
  margin-bottom: 15px;
}
.table-filter .form-group input:focus,
.table-filter .form-group select:focus {
  border: 1px solid var(--brand-color);
}
.table-filter .form-group input {
  width: 300px;
}
.table-export {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.export-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1px;
  background: #673ab703;
  color: #673ab7;
  border-radius: 3px;
  padding: 3px 10px;
  box-shadow: var(--shadow);
  cursor: pointer;
  font-weight: 500;
}
.export-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  border: 2px solid #d3d3d352;
  padding: 8px;
  border-radius: 3px;
  background: #3f51b503;
  box-shadow: 0px 1px 2px 0px #d3d3d3ad;
  cursor: pointer;
  transition: var(--transition);
}
.export-wrapper .export-item:hover {
  border: 2px dotted #0080006e;
}
.export-button svg {
  color: #673ab7;
  font-size: 20px;
}
.export-item span {
  font-size: 16px;
  font-weight: 500;
  color: #008000;
}

/* import */
.import-item {
  border: 2px dotted var(--blue-color) 57;
  box-shadow: unset;
  cursor: pointer;
}
.import-item:hover {
  border: 2px solid dotted var(--blue-color) !important;
}
.import-item label {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  color: var(--blue-color);
  font-weight: 500;
  cursor: pointer;
}
.import-item label svg {
  color: var(--blue-color);
}
.import-item input {
}

.table-export svg {
  cursor: pointer;
}

.excel-text {
  color: green;
}
.pdf-text {
  color: var(--danger-color);
}
.word-text {
  color: rebeccapurple;
}
.reset-button {
  color: #8e8e8e;
  font-size: 28px;
  background: var(--bg);
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paid-badge {
  background: #00968917;
  color: #009686;
  border: 1px solid var(--green-color) 70;
  font-weight: 500;
}
.overdue-badge {
  background: rgb(221 51 51 / 5%);
  color: var(--danger-color);
  border: 1px solid rgb(221 51 51 / 35%);
  font-weight: 500;
}
.pending-badge {
  background: rgb(255 152 0 / 7%);
  color: #ff9800;
  border: 1px solid rgb(255 152 0 / 48%);
  font-weight: 500;
}

.price-badge {
  color: #3f51b5;
  background: #ffffff;
  font-weight: 600;
  border-radius: 30px;
}

/* Settings page */
.settings-container {
  background: white;
}
.settings-wrapper {
  background: var(--bg-light);
}
.settings-wrapper form {
  /* display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 30px; */
  width: 100%;
}
.settings-left-wrapper {
  width: 100%;
}
.settings-left-wrapper button {
  text-align: end;
}
.settings-right-card {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--bg-light);
  box-shadow: 0px 1px 4px -1px lightgrey;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.settings-right-header {
  background: rgba(121, 218, 232, 0.15);
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  text-align: center;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: var(--shadow);
  position: relative;
}
.settings-card-body {
  margin-top: 10px;
  padding: 10px;
}
.settings-right-header label {
}

.setting-title {
}
.setting-title h2 {
  font-size: var(--title-size);
  margin-bottom: 10px;
}
.setting-title p {
  color: var(--p-color);
  font-size: 15px;
  line-height: 20px;
}
/* Analytics screen */
.analytics-container {
  position: relative;
}
.analytics-wrapper {
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-gap: 10px;
}
.analytics-canvas {
}
.analytics-header {
  background-color: var(--bg-light);
  border-radius: 3px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
}
.analytics-header svg {
  color: gray;
}
.analytics-header-icons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 15px;
  align-items: center;
}
.analytics-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%;
  background-color: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
  box-shadow: var(--shadow);
  height: 100%;
}
.analytics-sidebar {
  position: sticky;
  top: 0px;
}
.chat-init-not-found {
  height: 85vh;
}
.analytics-guide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  width: 70%;
  height: 100%;
  border-radius: 3px;
  text-align: center;
}
.analytics-guide h2 {
  color: lightgray;
  font-family: 'Montserrat';
}
.analytics-guide p {
  color: lightgray;
  font-size: 16px;
}
.analytics-guide img {
  opacity: 0.4;
}
.analytics-guide svg {
  font-size: 35px;
  opacity: 0.4;
}
.analytics-sidebar-item {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 202px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.analytics-fileds-wrapper {
}
.analytics-sidebar-fileds,
.analytics-sidebar-labels {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
}
.analytics-sidebar-labels {
  margin-top: 10px;
}

/* width */
.analytics-sidebar-item::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.analytics-sidebar-item::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #f1f1f1;
}

/* Handle */
.analytics-sidebar-item::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.178);
  border-radius: 30px;
}

/* Handle on hover */
.analytics-sidebar-item::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.field-item-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 3px;
  font-size: 17px;
  background: rgb(133 166 177 / 4%);
  padding: 4px;
  cursor: pointer;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  background: #009688;
  height: 40px;
  border-radius: 5px;
}
.field-item-wrap.active {
  height: max-content;
  overflow-y: scroll;
}
.field-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 6px;
  font-size: 17px;
  background: #3f51b5;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
}
.x-axis-item {
  background: #9c27b0;
}
.field-item span {
  font-weight: 300;
  color: white;
  font-size: 16px;
}

.field-item.active {
  background: #add8e64a;
}
.field-item.active svg,
.field-item.active span {
  color: var(--brand-color);
  font-weight: 400;
}
.field-item svg {
  color: white !important;
}
.field-child-item {
  font-weight: 400;
  color: gray;
  font-size: 15px;
  background: #8080800d;
  padding: 3px 10px;
  cursor: move;
  transition: var(--transition);
  border-radius: 3px;
}
.field-child-item.active {
  background: black;
}
.field-child-item:hover {
  background: #0058501c;
  color: #009688;
}
/* label item */
.label-item {
  justify-content: space-between;
}
.label-content {
  display: inline-flex;
  grid-gap: 5px;
}
.label-action {
  display: inline-flex;
}

.analytics-sidebar-title {
  background-color: var(--bg-light);
  border-bottom: 2px solid #d3d3d321;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
}
.analytics-sidebar-title h3 {
  font-weight: 600;
  font-size: var(--title-size);
  color: darkgray;
}
.chart-text {
  /* background: #00968714; */
  padding: 4px;
  font-size: 30px;
  border-radius: 3px;
}
.chart-text.green {
  color: var(--green-color);
}
.chart-text.purple {
  color: #f44336;
}
.chart-text.orange {
  color: #9c27b0;
}
.chart-text.blue {
  color: #3f51b5;
}
.chart-text.skyblue {
  color: #2196f3;
}

.settings-wrapper .form-group input,
.settings-wrapper .form-group select,
.settings-wrapper .form-group textarea {
  color: var(--gray);
  border: 1px solid var(--bg-light);
}

.export-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  padding: 10px 0px;
}
.export-wrapper svg {
  font-size: 25px;
}

.error-text {
  color: var(--danger-color) !important;
  font-size: 13px;
  line-height: 20px;
}
.error-input {
  border: 1px solid var(--danger-color) !important;
}
.logout-btn {
  display: flex;
  grid-gap: 4px;
  /* background: #e91e6314; */
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: var(--transition);
  border: 1px solid transparent;
}
.logout-btn:hover {
  border: 1px solid #f080801f;
  transform: translateY(-1px);
}
.logout-btn,
.logout-btn svg {
  color: var(--wip-color) !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 12px;
}
.logout-btn svg {
  font-size: var(--title-size);
}

.loading-wrapper {
  position: absolute;
  inset: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 5rem;
  border-left: 3px solid #4c4c4c;
  position: relative;
  transform: rotate(0deg);
  animation: spinning 1s linear infinite;
  z-index: 9999;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(-360deg);
  }
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}
.loader3 span {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #3f51b5;
  animation: loader3 1.5s linear infinite;
}
.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.error-box {
  background: #f443360d;
  border-radius: 3px;
  /* text-align: center; */
  padding: 13px;
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.table-data-not-found {
  /* background: #e91e6314; */
  color: red;
  font-weight: 500;
  padding: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  margin: 10px 0px;
}
.email-span {
  color: #009686;
}
.edit-disabled {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.btn-disabled {
  cursor: not-allowed !important;
  background: #c1c1c1 !important;
  color: white !important;
}
.delete-disabled {
  cursor: not-allowed;
  color: #e91e1e6e !important;
}
.btn-disabled button {
  cursor: not-allowed;
  background: #c1c1c1 !important;
}
.export-disabled {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.export-disabled span,
.export-disabled svg {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.btn-import {
  padding: 11px 20px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--blue-color);
  font-size: 15px;
  height: 100%;
  font-weight: 500;
}
.group-right-buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 5px;
  align-items: center;
}

.clear-data {
}
.clear-data button {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  background: #f44336;
}
.clear-data button svg {
  font-size: 16px;
  color: white;
}
.import-data-body-btn {
  padding: 10px 15px !important;
  background: var(--blue-color) !important;
  font-size: 15px !important;
  margin-top: 10px;
  transition: var(--transition);
}
.import-data-body-btn:hover {
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
}
.mini-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-light);
  padding: 10px 0px;
  border-radius: var(--radius);
  margin-bottom: 10px;
}
.filename-span {
  font-size: 16px;
  color: #607d8b;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 5px;
}

/* sheet tab */
.sheets {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}

.sheet {
  background: #00968812;
  border-radius: 5px;
  font-size: 14px;
  padding: 7px 10px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--green-color);
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 10px;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px transparent;
  cursor: pointer;
  transition: var(--transition);
  width: max-content;
}
.sheet.active {
  color: var(--white-color);
  background: var(--green-color);
  box-shadow: var(--shadow);
}
.save-data-btn {
  background: var(--green-color) !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}

.save-data-btn svg {
  color: white !important;
  font-size: 16px;
}
.table-add-new button svg {
  font-size: 20px;
  /* color: white !important; */
}
.saved-btn {
  background: #a0a0a0 !important;
}

.not-found-data {
  /* position: fixed; */
  inset: 0;
  z-index: 9999;
}
.not-found-data svg {
  font-size: 40px;
  color: lightgray !important;
}
.not-found-data .analytics-body {
  margin-top: unset;
}

/* accoridian */
.accordion-wrapper {
  border-radius: 3px;
  position: relative;
  border: 2px solid lightgray;
  border-right: unset;
  border-left: unset;
  border-bottom: unset;
  padding: 10px 0px;
  border-radius: unset;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  font-weight: 700;
  cursor: pointer;
  color: #666;
  padding: 0.5em 0px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--heading-color);
  transition: var(--transition);
  font-family: 'Poppins';
}
.accordion-title h4 {
  font-size: 17px;
  font-weight: 600;
  align-items: center;
  color: var(--accent-color);
  text-transform: capitalize;
  font-family: 'Poppins';
}
.accordion-list {
  margin-top: 10px;
  margin-left: 10px;
}
.accordion-title::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--loading-dot-bg);
}
/* .accordion-title:hover, .accordion-title.open {
  color: var(--brandColor);
} */
.accordion-title.open {
  /* position: absolute; */
  width: 100%;
}
.accordion-title.open::after {
  content: '';
  border-top: 0;
  border-bottom: 5px solid;
}
.accordion-title.open .accordion-item {
  overflow: unset !important;
}
.accordion-content {
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  font-size: 16px;
  font-weight: 400;
}
.analytics-table {
  background: var(--bg-light);
  margin-top: 10px;
  border-radius: 5px;
  height: 80vh;
  overflow-y: auto;
  padding: 20px;
}

/* recent chat scrollbar width */
.analytics-table::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.analytics-table::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.analytics-table::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.analytics-table::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

/* dnd */
.y-axis-drag-canvas {
  background: var(--bg-light);
  border: 2px dotted #8080804f;
  padding: 5px;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
}
.board {
  border: 2px dotted gray;
  background: var(--bg-light);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: 200px;
}
.board.active {
  border: 2px dotted green;
}

.main_content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 30px;
  margin: 20px;
}

/* analytics chart wrapper */
.analytics-chart-mask {
  background: var(--bg-light);
  padding: 15px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  margin-top: 10px;
  max-width: 100%;
  width: 100%;
  height: 470px;
}

.analytics-chart-mask svg {
  font-size: 13px;
}

/* React tabs */
.react-tabs__tab {
  border: unset !important;
}
.react-tabs__tab:focus {
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected:focus {
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected {
  border-radius: 3px;
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected svg {
  background: #00968714;
}

.text-chart-analytics-tbody tr:first-child {
  font-weight: normal !important;
}
.recharts-text.yAxis-value {
  fill: #3f51b5;
  font-weight: 500;
  position: relative;
}
/* .recharts-text.yAxis-value tspan::before {
  content: '';

  bottom: 0px;
  position: absolute;
} */

.new-shape {
  background: red;
  height: 20px;
  width: 20px;
}
.recharts-text.xAxis-value {
  fill: #9c27b0;
  font-weight: 300;
  text-transform: capitalize;
}
.pie-chart-analytics-wrapper {
  width: 100%;
  height: 100%;
}
.pie-chart-analytics-wrapper canvas {
  width: auto !important;
  height: 100% !important;
  margin: auto;
}

/* user profile screen
====================== */
.user-profile-section {
  background: var(--bg-light);
  border-radius: var(--radius);
  margin: 15px;
  position: relative;
}
.user-profile-header-wrapper {
  background: var(--bg);
  padding: 30px;
  border-radius: var(--radius);
  position: relative;
}
.user-profile-header-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px;
}
.user-profile-header-thumb {
  width: 130px;
  height: 130px;
  position: relative;
  box-shadow: var(--shadow);
  border-radius: 50%;
  position: relative;
}
.user-profile-header-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}
.user-update-profile {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--bg-light);
  box-shadow: var(--hover-shadow);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.user-update-icon {
}
.user-profile-content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.user-profile-content .settings-title {
  background: var(--bg-light);
  border-radius: 30px;
  padding: 3px 10px;
  box-shadow: var(--shadow);
  font-size: 20px;
  color: var(--h-color);
}
.settings-profie-content {
  margin-top: 10px;
}
.user-profile-content h2 {
  font-size: 26px;
  color: var(--h-color);
}
.user-profile-role-and-department {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
}
.user-profile-role-and-department span {
  padding: 2px 14px;
}
.user-profile-body {
  margin-top: 30px;
}
.user-profile-body .form-field-wrapper {
  max-width: 600px;
}
.user-title-name {
  color: var(--brand-color);
}
.create-user-profile {
  width: 70px;
  height: 70px;
}
.create-user-profile .create-user-profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.create-user-profile-update-icon-wrapper {
  height: 30px;
  width: 30px;
  right: -8px !important;
}
.create-user-profile-update-icon-wrapper svg {
}
.mode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mode svg {
  height: 100%;
}
.no-number-badge {
  background: #ffe50033;
  padding: 2px 5px;
  border-radius: 30px;
  font-size: 13px;
}
.user-join-date p {
  font-size: 12px;
  background: var(--bg-light);
  font-weight: 500;
  width: fit-content;
  padding: 1px 10px;
  border-radius: 30px;
  box-shadow: var(--hover-shadow);
}
.user-join-date {
  margin-top: -5px;
}
.user-update-date {
  position: absolute;
  bottom: -11px;
  right: 30px;
}

/* .password-error {
  border-bottom: 3px solid red;
} */
.settings-thumb {
  margin: auto;
  width: fit-content;
  border-radius: unset;
  height: 100px;
  box-shadow: unset;
  background: white;
  padding: 8px;
  border-radius: 3px;
  box-shadow: var(--shadow);
}
.settings-thumb img {
  border-radius: unset;
  object-fit: contain;
  border-radius: 3px;
}
.settings-upload-icon {
  bottom: -11px;
  right: -11px;
}
.header-site-title {
  font-size: var(--title-size);
}
.header-sidebar-logo {
  /* max-width: 100%; */
  width: 100%;
  height: 35px;
  justify-content: flex-start;
}
.header-sidebar-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pie-chart-column-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}
.pie-chart-style {
}

/* loading */
.mini-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffffff7;
  width: 100%;
  height: 100%;
}
.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.disabled {
  cursor: not-allowed !important;
  background: #b7b7b759 !important;
}
.disabled span,
.disabled svg {
  color: white !important;
}
.disabled:hover {
}
.color-green,
.text-green {
  color: green;
  font-weight: 300;
}
.active-user-row {
  background: #0080001c !important;
  border-left: 3px solid var(--brand-color);
}

/* new loading */
/* <div className="xui-loader xui-loader-small xui-button--loader xui-loader-retain-layout" role="progressbar">
<div className="xui-loader--dot"></div>
<div className="xui-loader--dot"></div>
<div className="xui-loader--dot"></div>
</div> */
.xui-loader {
  align-items: center;
  color: rgba(0, 10, 30, 0.65);
  display: flex;
  justify-content: center;
}
.xui-loader-retain-layout {
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: var(--model-bg);
}
.xui-loader--dot {
  animation: xui-loader-animation 1.3s infinite
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: both;
  background-color: var(--loading-dot-bg);
  border-radius: 50%;
  box-shadow: 0 0 1px var(--loading-dot-bg);
  display: inline-block;
  transform-style: preserve-3d;
  vertical-align: middle;
}
.xui-loader--dot:nth-child(1) {
  animation-delay: -0.32s;
}
.xui-loader--dot:nth-child(2) {
  animation-delay: -0.16s;
}
.xui-loader--dot:nth-child(3) {
  animation-delay: 0s;
}
.xui-loader-medium .xui-loader--dot {
  height: 14px;
  margin-right: 3px;
  width: 14px;
}
.xui-loader-small .xui-loader--dot {
  height: 12px;
  margin-right: 2px;
  width: 12px;
}
.xui-loader-xsmall .xui-loader--dot {
  height: 10px;
  margin-right: 2px;
  width: 10px;
}
@keyframes xui-loader-animation {
  0%,
  100%,
  80% {
    transform: scale(0.5) rotate(0);
  }
  40% {
    transform: scale(1) rotate(0.02deg);
  }
}

.xero-table-wrapper {
  background: var(--bg);
  position: relative;
  height: 100%;
  /* width: 100%; */
  margin-top: 10px;
  overflow-x: auto;
  height: 70vh;
}

.xero-table-wrapper table {
  /* margin: 10px; */
  background: var(--bg-light) !important;
  border-radius: 3px;
}
.xero-error {
  font-size: 20px;
  background: #ff45001c;
  color: #f44336;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  /* margin: 10px 0px; */
  text-align: center;
}
.table-header-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}
.table-header-button-wrapper .table-header-button {
  text-align: center;
  transition: var(--transition);
}
.table-header-button-wrapper .table-header-button:nth-child(2) {
  background: #0088cc;
}

.table-header-button-wrapper .table-header-button:nth-child(2):hover {
  background: var(--green-color);
}

.notify-container {
  background: white;
  padding: 20px 25px;
  border-radius: 5px;
  /* height: 400px; */
  /* width: 300px; */
  position: absolute;
  top: 46px;
  right: -8px;
  z-index: 99;
  box-shadow: var(--hover-shadow);
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  transition: all 0.3s ease-in-out;
}
.choose-table-header {
}
.choose-amount-filed input {
  width: 100px;
  text-align: center;
}
.choose-option {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
}
.choose-option .choose-button {
  width: 100%;
  border: 2px dotted #c3c3c3;
  background: #f2f4f81a;
  color: #0088cc;
  box-shadow: unset;
  font-weight: 500;
  transition: var(--transition);
  min-width: min-content;
}
.choose-option .choose-button svg {
  color: #0088cc !important;
}

.react-tabs__tab--selected .choose-button {
  background: #607d8b;
  color: white;
  border: 2px solid transparent;
}
.react-tabs__tab--selected .choose-button svg {
  color: #fff !important;
}

.choose-table-header select {
  font-size: 14px;
}

.choose-item button {
  padding: 8px 9px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--brand-color);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  min-height: 33px;
  min-width: 100px;
}
.choose-send-button button {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  background: #009688;
  margin-top: 10px;
  background-image: linear-gradient(
    270deg,
    rgba(100, 181, 239, 0) 48.44%,
    #00a797 75.52%,
    rgba(100, 181, 239, 0) 100%
  );
  background-repeat: no-repeat;
  animation: bg-move linear 5s infinite;
}
button.disabled {
  background: darkgray;
}
.api-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.api-item {
  background: #80808024;
  color: #607d8b;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 400;
}
.api-item.react-tabs__tab--selected {
  background: var(--blue-color);
  color: white;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
}
.api-item.react-tabs__tab--selected:focus {
  border: unset !important;
  outline: none !important;
}

.aged-title {
  inset: 0;
}

/* analytics filter 
=================== */
.analytics-filter-container {
  position: fixed;
  right: -100px;
  background: white;
  height: 100%;
  top: 0px;
  width: 300px;
  box-shadow: 0px 0px 7px 0px lightgray;
  padding: 20px;
  transition: var(--transition);
  visibility: hidden;
  opacity: 0;
}
.analytics-filter-container.active {
  right: 0px;
  opacity: 1;
  visibility: visible;
  z-index: 999;
}
.dropdown-value-filter {
  width: 100%;
  height: inherit;
}
.dropdown-value-filter form {
  height: 100%;
}
.value-filter-container {
  box-shadow: unset;
  border-radius: unset;
  position: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 78vh;
  overflow-y: scroll;
  padding-right: 10px;
  grid-gap: 10px;
}

/* recent chat scrollbar width */
.value-filter-container::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.value-filter-container::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.value-filter-container::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.value-filter-container::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.filter-title {
  color: var(--brand-color);
  font-size: 20px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #fafafa;
  padding-bottom: 10px;
}
.filter-times {
  color: var(--orange-color);
  font-size: 30px;
}
.analytics-choose-amount-filed {
  width: 100%;
}
.analytics-filter-btn {
  margin-top: 12px;
}

@-webkit-keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.filter-sidebar-title-and-reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-sidebar-title-and-reset svg {
  color: var(--orange-color);
  font-size: 20px;
}
.filter-sidebar-title-and-reset h5 {
  text-transform: capitalize;
  font-size: 15px;
  line-height: 0px;
  font-weight: 600;
  color: #009688;
}
.item-action-btn {
  display: flex;
}
.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}
.label-wrapper li {
  display: inline-block;
}

.Custom-Tooltip {
  background: green;
  padding: 10px;
  color: white;
  border: unset !important;
  outline: none;
}
.tooltip-label {
  color: wheat;
}
.chat-send-time {
  font-size: 9px;
  margin-top: 16px;
  position: absolute;
  bottom: -4px;
  right: 17px;
  width: max-content;
}
.chat-message-wrapper {
  position: relative;
  width: 100%;
}
.channel-right {
  position: relative;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  grid-gap: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.channel-right svg {
  font-size: 16px !important;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 999;
}
.create-group-logo {
  width: 80px;
  height: 80px;
}
.required {
  color: red !important;
  border: 1px solid red !important;
}
.required-input {
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.not-shadow {
  box-shadow: unset !important;
}
.chat-cov-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fetch-link-item {
  flex-direction: row;
}
.icon-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.integrate-btn {
  border: 1px solid var(--green-color);
  padding: 10px 20px;
  border-radius: 30px;
  margin-top: 10px;
  color: var(--green-color);
  font-size: 14px;
  box-shadow: var(--shadow);
  transition: all 0.3s ease-in-out;
}
.integrate-btn:hover {
  background: var(--green-color);
  color: white;
  transform: translateY(-3px);
}
.integrate-status {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #0096880d;
  border-radius: 30px;
  padding: 1px 11px;
  color: #009688;
  font-weight: 500;
  font-size: 12px;
}
.not-connected {
  background: rgba(178, 34, 34, 0.068);
  color: firebrick;
}

/* notification screen */

.notification-tbody {
}
.notification-tbody tr {
  border-bottom: 1px solid #9e9e9e26;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out;
}
.hover-icons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.hover-icons svg {
  font-size: 20px;
  margin-left: 5px;
}

.hover-icons {
  /* visibility: hidden;
  opacity: 0; */
  display: none;
}
.hover-date-item {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  align-items: center;
  margin-right: 8px;
}
.date-item input {
  font-size: 15px;
}
.notification-tbody tr:hover {
  background: #f2f4f8;
}
.notification-tbody tr:hover .hover-icons {
  /* visibility: visible;
  opacity: 1; */
  display: block !important;
  text-align: end;
  margin-top: 5px;
}
.notification-tbody tr:hover .date-item {
  display: none;
}
/* 
.notification-tbody tr:hover .hover-date-item {
  display: none !important;
} */
.notification-tbody td {
  border: unset !important;
}
.notification-tbody tr:first-child {
  border-top: unset !important;
}
.notification-tbody tr:last-child {
  border-bottom: unset !important;
}
.noti-title {
  font-weight: 600;
  font-size: 15px;
  color: #2c404a;
}
.noti-title p {
  width: max-content;
}
.flex-noti-title {
  display: flex;
  justify-content: flex-start;
  grid-gap: 7px;
  align-items: center;
}
.reset-noti {
  background: unset;
  width: unset;
  height: unset;
  font-size: 22px;
}
.noti-filter input,
.noti-filter select {
  background: unset;
  border: 1px solid #d3d3d363 !important;
  height: 40px;
}
.details-sidebar-container {
  position: absolute;
  inset: 0;
  background: #0000006e;
  z-index: 999;
  width: 100%;
  height: auto;
  bottom: 0px;
  visibility: hidden;
  right: -400px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.details-sidebar-container.active {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.details-sidebar {
  position: absolute;
  top: 0px;
  right: -100px;
  bottom: 0px;
  height: 100%;
  width: 600px;
  z-index: 9999;
  background: white;
  box-shadow: 0px 0px 4px -1px #607d8b;
  transition: all 0.3s ease-in-out;
  padding: 40px;
}
.details-sidebar.active {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.details-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #607d8b0f;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.left-sidebar-details {
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  align-items: center;
}
.left-sidebar-details h2 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  color: var(--green-color);
}
.left-sidebar-details h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4c4c4c;
}
.right-sidebar-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.mb-unset {
  margin-bottom: unset;
}
.mb-unset select {
  font-size: 14px;
}
.action-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.action-icons svg {
  font-size: 20px;
}
.close-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  background: #e91e630f;
  border-radius: 50%;
}
.close-icon svg {
  font-size: 31px;
  padding: 4px;
  color: red;
}
.left-sidebar-details-thumb {
  width: 40px;
  height: auto;
}
.left-sidebar-details-thumb img {
  width: 100%;
  height: 100%;
}
.sidebar-article h2 {
}
.sidebar-article h3 {
}

/* body */
.details-sidebar-body {
  max-height: 415px;
  overflow-y: auto;
  min-height: fit-content;
  padding-right: 10px;
}

/* width */
.details-sidebar-body::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.details-sidebar-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
.details-sidebar-body::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.39);
  border-radius: 30px;
}

/* Handle on hover */
.details-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.26);
}
.table-social-icon {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain !important;
}
.sidebar-agent {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-top: 20px;
}
.agent-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.agent-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.agent-details {
}
.agent-details h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 14px;
  text-transform: capitalize;
}
.agent-details p {
  color: gray;
  font-size: 13px;
}
.details-sidebar-wrap {
  margin-top: 5px;
}
.details-sidebar-wrap h2 {
  font-size: 19px;
  margin-bottom: 10px;
  font-weight: 500;
}
.details-sidebar-wrap p {
  font-size: 15px;
  text-transform: capitalize;
}
.sub-menu-wrapper {
  margin-left: 20px;
}
.sub-menu-wrapper ul {
  display: unset !important;
}
.sub-menu-wrapper ul li {
  border: unset !important;
  height: unset !important;
}
.dropdown-menu-item {
  height: 43px;
  overflow: hidden;
}

.bot-demo-launch-btn {
  border: 1px solid #00968821;
  color: var(--green-color);
  background: #0096880f;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.editor-not-allow {
  background: white;
  border-radius: 3px;
  box-shadow: var(--shadow);
  margin: 20px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
}
.editor-not-allow h1 {
  color: var(--gray-color);
}
.editor-na-alart {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
}
.editor-na-alart span {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: indianred;
}
.editor-na-alart svg {
  font-size: 60px;
  color: red;
}
.appointment-form {
  width: 90%;
  box-shadow: 0px 0px 4px 0px #d3d3d399;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.appointment-btn {
  background: var(--green-bg);
  padding: 7px 15px;
  border-radius: 30px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  /* width: 80px; */
}
.form-group.appo-group {
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  width: 100%;
}
.space-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: unset !important;
  margin-top: 10px;
}
.form-group.appo-group input,
.form-group.appo-group select {
  font-size: 14px;
}
.form-group.appo-group:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 7px;
}
.form-group.appo-group label {
  width: 30%;
}
.form-group.appo-group input,
.form-group.appo-group select {
  width: 70%;
}
.factory-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
}
.factory-btns svg {
  background: #0096880f;
  border-radius: 30px;
  font-size: 40px;
  padding: 8px;
}
.appo-title {
  margin-bottom: 10px;
}
.appo-title h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #145da0;
  width: max-content;
}
.appo-title p {
  font-size: 13px;
  line-height: 18px;
}
.error-notifi {
  color: #e62f22;
  font-weight: 500;
}

.curosul-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
}
.slider-item {
  box-shadow: var(--hover-shadow);
  border-radius: 5px;
}
.slider-item li {
  border-bottom: 1px solid #d3d3d366;
  padding: 3px 10px;
  margin-bottom: unset !important;
  font-size: 11px;
  line-height: 16px;
}
.slider-item li:last-child {
  border-bottom: unset;
}
.slider-item img {
  width: 50px;
  padding: 3px 10px;
}
.renew-btn {
  color: var(--brand-color);
  font-weight: 500;
}

.featured-list-bot-thumb {
  width: 70px;
  height: 60px;
}
.featured-list-bot-thumb img {
  border-radius: unset !important;
  object-fit: contain;
}

/* Chatbot */
.chatbot-section {
  position: relative;
}
.chatbot-widget-wrapper {
  position: fixed;
  right: 30px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 15px;
  z-index: 99999;
  /* visibility: hidden;
  opacity: 0; */
}

/* chatbot icon */

.chatbot-times-icon {
  box-shadow: var(--brand-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  overflow: hidden;
  z-index: 9999;
  position: absolute;
  left: -35px;
  top: 0px;
}
.chatbot-times-icon:hover .chatbot-times-icon svg {
  color: green;
}
.chatbot-times-icon svg {
}
.chatbot-times-icon img {
}
.chatbot-message-body {
  padding: 5px 0px !important;
}
.factory-error-box {
  background: #f2f4f8;
  padding: 10px;
  border-radius: 16px;
}
.factory-error-box li {
  margin: unset !important;
  line-height: 22px;
}
.chatlogs-send-btn {
  width: 80px !important;
  height: 33px;
}
.emoji-wrap {
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.emoji-wrap.active {
  position: absolute;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}
.sub-group-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  margin: 10px 0px;
  flex-wrap: wrap;
}
.api-sub-item {
  background: var(--bg-light);
  padding: 0px 7px;
  border-radius: 4px;
  color: var(--blue-color);
  border: 1px solid #196d6342;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.api-sub-item-active {
  background: var(--blue-color);
  padding: 0px 7px;
  border-radius: 4px;
  color: white;
  border: 1px solid #196d6342;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.api-sub-item:hover {
  background: var(--blue-color);
  color: white;
}

/* bot preview */
.chatbot-mini-preview-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 20px;
  padding: 6px 20px;
  grid-gap: 35px;
  cursor: pointer;
}
.chatbot-mini-preview-wrap.active {
  display: none;
}
.chatbot-mini-preview-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.bot-preview-img {
  width: 19px;
}
.send-us-message {
  font-weight: 400;
  color: white;
}
.chatbot-mini-preview-right {
}
.chatbot-mini-preview-right h5 {
  font-size: 12px;
  color: white;
  font-weight: 500;
}

/* abdus_shakur */
.report-block {
  margin-top: 10px;
  /* background-color: white; */
  padding: 5px 5px;
  border-radius: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.report-block h5 {
  font-weight: 600;
  margin: 10px 0px;
  font-size: 15px;
  border-bottom: 1px solid #607d8b;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 3px;
  margin-bottom: 10px;
}
.report-block h6 {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 14px;
}
.refill-item {
  height: 100px;
  width: 100px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
}
.refill-img {
  border-bottom: 1px solid #0393d6;
  border-top: 1px solid rgb(3, 147, 214);
  display: flex;
  flex-direction: column;
}
.refill-img img {
  width: 60% !important;
  margin: 0 auto;
}
.refill-item h6 {
  padding: 6px 0 0 0;
  text-align: center;
  font-size: 14px;
}
.refill-item button {
  background-color: rgb(3, 102, 148);
  color: white;
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 50px;
  margin: 8px auto;
}
.delete-chat-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}

.delete-chat-icon span {
  font-size: 13px;
}
.refresh-icon {
  background: unset !important;
}
.refresh-icon svg {
  color: #fff;
  fill: unset !important;
}

.welcome-the-bot {
  position: absolute;
  background: green;
  padding: 20px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
}
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}
.welcome-the-bot p {
}
.welcome-the-bot button {
}
.extarnal-link {
  border: 1px solid var(--green-bg);
  color: var(--green-bg);
  border-radius: 4px;
  padding: 8px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 4px;
  font-weight: 600;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.extarnal-link:hover {
  color: var(--green-color) !important;
}
.extarnal-link svg {
  color: var(--brand-color);
  font-size: 15px;
}
.react-datepicker__input-container {
  width: max-content !important;
}
.react-datepicker__input-container input,
.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
  font-size: 13px !important;
  color: #000;
}
/* react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--select */
.react-datepicker {
  font-family: var(--font-montserrat);
  font-size: 13px;
}
.booking-date-field {
}
.booking-date-wrapper {
  margin-top: 10px;
}
.bot-form {
  margin-bottom: unset;
}
.bot-form:last-child {
  margin-bottom: 10px !important;
}
.bot-form:last-child {
  margin-bottom: 10px;
}
.bot-form input {
  width: auto;
  background: white;
  border: 1px solid #d3d3d382;
  font-size: 14px;
  display: block;
}
.bot-form label {
}
.bot-form-btn {
  margin-top: 10px;
}
.chat-bot-colse-img {
  width: 100%;
  height: 100%;
}
.chat-bot-colse-img img {
  width: 100%;
  height: 100%;
}
.curosol-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
}
.curosol-item {
  cursor: pointer;
}
.curosol-item img {
  width: 90px;
  border-radius: 3px;
  height: 70px;
  object-fit: cover;
}
.curosol-item p {
  font-size: 13px;
  line-height: 14px;
}
.bot-demo-wrapper {
  position: absolute;
  background: white;
  width: 100%;
  inset: 0;
  height: 100%;
  background-image: url('https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGlnaHRpbmd8ZW58MHx8MHx8&w=1000&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Swiper bullet */

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
  text-transform: none !important;
  font-weight: 800 !important;
  font-family: var(--font-montserrat);
  color: var(--brand-color) !important;
}
.chatbot-swiper-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.booking-summery-wrap {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  border-top-left-radius: unset;
  grid-gap: 0px;
}
.booking-summery-wrap li {
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
  margin: unset !important;
}
.booking-end-msg {
  border-top-left-radius: unset;
  margin-top: 5px;
}
.sum-item-room-type-badge {
  background: #3f51b5;
  padding: 1px 11px;
  border-radius: 15px;
  color: white;
  font-weight: 500;
  font-size: 13px;
}
.sum-item-badge {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker {
  border: 1px solid #aeaeae59 !important;
}
/* .dashboard-app-table-wrap {
  padding: 10px;
  background: var(--bg-light);
  margin-top: 10px;
  border-radius: 3px;
} */

.highlight-name {
  font-weight: 600;
  display: contents;
  color: #009688;
}

.payment-question-text {
  margin-top: 15px;
}
.mobile-version-kpi-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 10px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 15px;
}
.mobile-version-kpi-wrapper .featured-status-item {
  width: 100%;
  min-height: unset;
  height: unset;
  border-radius: 50%;
  padding: 10px;
  border: unset;
  position: relative;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 4px 0px black;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content {
  grid-gap: 5px;
  flex-direction: column-reverse;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content h3 {
  font-size: 13px;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content h1 {
  font-size: 31px;
}
.temp {
  width: 500px;
  height: 100%;
}
.mobile-kpi-item-one {
  /* background: #fc9d2b !important; */
}
.mobile-kpi-item-two {
  /* background: #1a58ff !important; */
}
.mobile-kpi-item-three {
  /* background: #19cbd8 !important; */
}

/* for the mobile kpi */
.featured-status-item.mobile-kpi-item {
  background: #fff !important;
  border: 2px solid green !important;
}
.mobile-kpi-content h1,
.mobile-kpi-content h3 {
  color: green !important;
}
/* 
.featured-status-item.mobile-kpi-item-two {
  background: #fff !important;
  border: 2px solid #004AAD !important;
}

.featured-status-item.mobile-kpi-item-three {
  background: #fff !important;
  border: 2px solid #0566FF !important;
} */

.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb {
  display: unset;
  width: unset;
  height: unset;
  box-shadow: unset;
  border-radius: unset;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb img {
  display: none;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb svg {
  color: #ffffff24;
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 30px;
}

/* new dashboard design */
.dashboard-new-design-wrapper {
  margin: 20px 0px;
  margin-top: unset;
  margin-bottom: 10px;
}
.dashboard-new-design-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
}
.dashboard-header-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 15px;
}
.dashboard-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0px;
  flex-direction: column;
}
.dashboard-title-wrap {
  margin: 20px 0;
  flex-direction: row;
  grid-gap: 15px;
  align-items: center;
}
.dashboard-header-left svg {
  font-size: 23px;
}
.dashboard-header-left h3 {
  color: var(--heading-color);
  font-size: var(--title-size);
  font-weight: 600;
  color: black !important;
}
.dashboard-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-header-right p {
  color: var(--brand-color);
  font-size: medium;
  font-weight: 500;
}
/* dashboard new kpi */
.dashboard-kpi-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 20px;
  margin: 20px 0px;
}
.dashboard-kpi-item {
  padding: 20px;
  border-radius: 4px;
  background: var(--bg-light);
  box-shadow: 0px 2px 2px -1px var(--shadowColor);
  display: flex;
  width: 100%;
  border: 2px solid #d3d3d32e;
  cursor: pointer;
  flex-direction: column;
  /* min-height: 100px; */
  /* padding-bottom: 0px; */
  position: relative;
}
.dashboard-kpi-item-not-selectable {
  padding: 20px 40px;
  border-radius: 4px;
  background: var(--bg-light);
  box-shadow: 0px 2px 2px -1px var(--shadowColor);
  display: flex;
  width: 100%;
  border: 2px solid #d3d3d32e;
  flex-direction: column;
  min-height: 120px;
  /* padding-bottom: 0px; */
  position: relative;
}

.dashboard-kpi-item.react-tabs__tab--selected {
  border-bottom: 2px solid var(--brand-color);
  border-radius: 6px;
}
.dashboard-kpi-item.react-tabs__tab--selected .kpi-item-icon {
  background: var(--brand-color);
}
.dashboard-kpi-item.react-tabs__tab--selected .kpi-item-icon svg {
  color: var(--white-color);
}
.kpi-item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kpi-item-header {
}
.kpi-item-icon {
  background: var(--brand-color-light);
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.kpi-item-icon svg {
  font-size: 35px;
  color: var(--brand-color);
}
.kpi-item-header h4 {
  color: var(--heading-color);
  font-size: var(--mini-size);
  line-height: 23px;
  font-weight: 500;
}
.kpi-item-header p {
  text-align: end;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  color: lightslategray;
}
.kpi-item-body h2 {
  color: var(--heading-color);
  font-size: 16px;
  line-height: 30px;
}
.kpi-item-footer {
  height: 6px;
  width: 100%;
  background: #e9ecef;
  border-radius: 20px;
  position: relative;
}
.dashboard-kpi-item .kpi-item-footer::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20%;
  height: 100%;
  background: #3b8ad8;
  border-radius: 20px;
}
.da-kpi-one .kpi-item-footer::before {
  width: 20%;
  /* background: #11b85c; */
}
.da-kpi-two .kpi-item-footer::before {
  width: 40%;
  /* background: #aa67cc; */
}
.da-kpi-three .kpi-item-footer::before {
  width: 10%;
  /* background: #ffa044; */
}
.da-kpi-four .kpi-item-footer::before {
  width: 70%;
  /* background: #34b5e5; */
}
.download-btn-wrap {
  background: var(--brand-color);
  height: 42px;
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow);
  gap: 10px;
  cursor: pointer;
  min-width: 185px;
}
.download-btn-wrap svg,
.download-btn-wrap span {
  color: white;
  font-weight: 500;
  text-transform: capitalize;
}
.download-btn-wrap span {
  font-size: var(--mini-size);
}
.download-btn-wrap svg {
  font-size: 25px;
}
.download-btn-wrap.disabled {
  background: #b7b7b759;
}
.dwn-btn {
  color: white;
  font-size: 25px;
}
.display-none {
  display: none;
}
.status-badge,
.unique-id {
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
}
.unique-id {
  background: #0096880d;
  color: #009688;
}
.status-badge.off-badge {
  color: white;
  background: #e91e63;
}
.status-badge.on-badge {
  background: #009688;
  color: white;
}
.reset-filter-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 6px;
  border: 1px solid #d3d3d363;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
.reset-filter-btn span {
  width: max-content;
}
.notification-sidebar-details-wrapper {
}
.notification-sidebar-details-wrapper li {
  /* display: flex;
  justify-content: flex-start;
  grid-gap: 50px;
  align-items: center; */
}
.notification-sidebar-details-wrapper li span:first-child {
  margin-right: 70px;
}
.kpi-tab-header-wrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 40px;
}
.kpi-tab-header-wrap li {
  font-weight: 500;
  color: var(--brand-color);
  width: 100%;
  background: #e2f0ec;
  border-radius: 7px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  text-align: center;
  font-size: var(--p-size);
  padding: 8px;
}
.interaction-count-badge {
  background: #5956e912;
  color: #929292 !important;
  font-weight: 700;
  padding: 3px 8px;
  font-size: 12px !important;
}
.kpi-tab-header-wrap li.react-tabs__tab.react-tabs__tab--selected {
  color: white;
  font-weight: 500;
  background: var(--brand-color);
  border-radius: 7px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.kpi-tab-header-wrap
  li.react-tabs__tab.react-tabs__tab--selected
  .interaction-count-badge {
  color: var(--blue-color) !important;
}

.noti-date-filter {
  box-shadow: unset;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 0px 10px;
}
.noti-date-filter input {
  border: unset !important;
}
.sec-badge {
  font-size: 16px;
  margin-left: 3px;
}

.new-area-chart {
  margin-top: 50px;
}

/* chatlog card */
.chatlog-profile-card {
  background: var(--bg-light);
  margin: 10px;
  position: relative;
  min-width: 290px;
}
.chatlog-card-thumb {
  width: 100%;
  height: 80px;
  background: var(--blue-color);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.chatlog-card-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  display: none;
}
.chatlog-card-wrapper {
  padding: 15px;
  padding-top: unset;
}
.chatlog-card-content {
  background: var(--bg-light);
  max-width: 90%;
  position: relative;
  margin: auto;
  margin-top: -15px;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
}
.chatlog-card-content.active {
  display: block;
}
.chatlog-card-content h2,
.chatlog-card-content h3,
.chatlog-card-content h4 {
  font-size: 17px;
  line-height: 25px;
}
.chatlog-card-content h3 {
  font-weight: 400;
  font-size: 15px;
  color: gray;
}
.chatlog-card-content h4 {
  font-size: 13px;
  color: darkgray;
}
.chatlog-card-info {
}
.chatlog-info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  border-top: 2px solid #d3d3d324;
  padding-top: 10px;
}
.chatlog-info-title p {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.chatlog-info-title svg {
  font-size: 15px;
}
.info-group {
}
.info-group label {
  font-size: 12px;
  color: #8080808a;
}
.info-group p {
  font-weight: 500;
  font-size: 15px;
}
.chatlog-card-media {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7px;
}
.chatlog-card-media img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
}
.chatlog-media-wrapper {
  cursor: pointer;
}
.card-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: -65px;
  border: 3px solid white;
}
.card-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.tab-content-wrapper {
}
.tab-dynamic-content {
  width: 100%;
  background: white;
  padding: 10px;
}
.w-100 {
  width: 100%;
}
.react-tabs__tab-panel {
  width: 100%;
}

/* new tab */
.kpi-dynamic-title {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #5956e924 !important;
  color: var(--blue-color);
  font-weight: 600;
  margin-bottom: 10px;
}
.kpi-dynamic-title.react-tabs__tab--selected .interaction-count-badge {
  color: var(--blue-color) !important;
}
.report-label-value {
}
.specialist-suggestion {
  font-size: 14px;
}
.specialist-suggestion p {
  font-size: 14px;
  color: #000000e3 !important;
}

.noti-table {
  display: flex;
  justify-content: flex-start;
  grid-gap: 65px;
}
.noti-table-header {
}
.noti-table-body {
}
.noti-table-header li {
}
.noti-table-body li {
}
.analytics-tab-icon-wrap {
}
.analytics-tab-icon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.analytics-tab-icon.react-tabs__tab--selected {
  background: #add8e661;
}
.mini-bot-footer {
}
.mini-bot-footer input {
  border-radius: 30px;
}
.mini-bot-footer button {
  border-radius: 30px;
  width: 62px;
  height: 33px;
}
.mini-bot-footer svg {
  color: white !important;
}
.ref-li {
  margin-bottom: 0px !important;
}
.bot-message-wrapper {
  height: 350px !important;
}
.bot-final-msg-body {
  height: 285px !important;
}
.bot-demo-message-wrapper {
  height: 322px !important;
}

.lang-parent-wrap {
}
.lang-child-wrap {
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
}
.sales-bot-wrapper {
  background-image: url('https://static.vecteezy.com/system/resources/previews/004/243/021/original/abstract-template-background-white-and-bright-blue-squares-overlapping-with-halftone-and-texture-free-vector.jpg');
}

/* bulk message */
.init-bulk-message {
  border-radius: 3px;
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
.init-bulk-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  width: 100%;
  height: 100%;
  font-family: var(--font-poppins);
  text-align: center;
  padding: 20px;
  /* border: 2px solid #d3d3d327;
  border-radius: 4px; */
}
.init-bulk-wrap h2 {
  font-size: 20px;
  color: gray;
  font-weight: 600;
}
.init-bulk-wrap p {
  font-size: 16px;
  color: #808080b3;
}
.init-button {
  background: var(--brand-color);
  color: var(--white-color);
  padding: 7px 10px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  font-family: var(--font-poppins);
  font-size: 13px;
  font-weight: 500;
}
.init-button svg {
  color: white;
}
.sample-btn {
}
.bulk-msg-create-user-form {
  width: 520px;
}
.number-import-item svg {
  color: gray;
}
.number-import-item span {
  color: gray;
}

/* bulk message */
.bulk-message-container {
  margin: 10px;
  background: white;
  padding: 20px;
}
.bulk-message-wrapper {
  display: grid;
  grid-template-columns: 4fr 9fr;
  grid-gap: 30px;
}
.full-width {
  width: 100%;
  position: relative;
}
.bulk-message-wrapper.full-width {
  grid-template-columns: 1fr;
}
.bulk-table-wrap {
  width: 100%;
}
.bulk-field-wrap {
  width: 100%;
}
.bulk-selected-field {
}
.bulk-selected-field label {
}

.bulk-selected-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 7px;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 3px;
  border: 2px solid #d3d3d34a;
  /* height: 80px; */
  overflow-y: auto;
  min-height: 20px;
}

/* bulk selected box scrollbar width */
.bulk-selected-box::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Track */
.bulk-selected-box::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Handle */
.bulk-selected-box::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 5px;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Handle on hover */
.bulk-selected-box::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.bulk-selected-box li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  background: #5d86f617;
  border-radius: 30px;
  padding: 0px 6px;
  /* display: inline-block; */
  color: var(--brand-color);
  font-weight: 600;
  font-size: 12px;
}
.bulk-selected-box li svg {
  cursor: pointer;
  font-size: 19px;
}
/* .flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 7px;
} */
.bulk-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #d3d3d31f;
  padding-bottom: 10px;
  margin-top: -10px;
}
.bulk-message-header h3 {
  font-size: var(--title-size);
  color: var(--gray-color);
  font-weight: 600;
}
.c-btn {
  background: var(--blue-color);
  color: white;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  text-align: center;
  padding: 10px 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  min-width: 120px;
  min-height: 40px;
  width: max-content;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bulk-media-thumb {
  border: 2px dotted lightgray;
  width: 100%;
  height: 100px;
  display: flex;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bulk-media-thumb img {
  width: 100%;
  height: 100%;
  border-radius: unset;
}
.bulk-update-profile {
  position: unset;
  height: auto;
  width: 100%;
  box-shadow: unset;
}
.bulk-profile {
  position: unset;
  width: 100%;
  border-radius: unset;
  margin: 0px auto;
  box-shadow: unset;
  grid-gap: 8px;
}
.bulk-group {
}
.bulk-group input,
.bulk-group textarea {
  border: 1px solid #d8d8d8;
  background: white;
}

.bulk-group input:focus,
.bulk-group textarea:focus {
  border: 1px solid var(--brand-color) !important;
}

.bulk-group textarea {
  height: 100px;
}
.bulk-group input::placeholder,
.bulk-group textarea::placeholder {
  font-size: 14px;
}
.bulk-table td,
.bulk-table th {
  border: unset;
  padding: 5px;
}
.table-wrap-bulk {
  height: 75vh;
  overflow-y: auto;
}

/* recent chat scrollbar width */
.table-wrap-bulk::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.table-wrap-bulk::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.table-wrap-bulk::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.table-wrap-bulk::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.list-of-bulk-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #fafafa;
  padding-bottom: 10px;
  margin-bottom: 10px;
  grid-gap: 15px;
  position: relative;
}
.list-of-bulk-title p {
  font-size: 14px;
  color: unset;
}
.list-of-bulk-title svg {
  color: #a4a4a4;
}
.list-of-bulk-title .bulk-clear-icon {
  position: absolute;
  top: 5px;
  right: 6px;
  font-size: 25px;
  z-index: 9999;
  background: white;
}
.bulk-search-input-wrap {
  position: relative;
}
.not-yet-selected {
  background: #e9565612;
  color: var(--brand-color);
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin-bottom: 5px;
}
.not-yet-selected p {
  font-size: 13px;
  font-weight: 500;
}
.form-check-input {
  width: 17px;
  height: 17px;
}
.bulk-media-preview {
  width: 100%;
  height: 300px;
  position: relative;
}
.bulk-media-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.media-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  background: #ffffffb4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-remove-icon {
  font-size: 28px;
  color: firebrick;
}
.loading-wrap {
  background: #80808033;
  border-radius: 4px;
}
.bulk-import-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.bulk-import-title span {
  font-size: 17px;
  font-weight: 500;
  color: var(--blue-color);
}
.bulk-import-title svg {
  color: var(--blue-color);
}
.init-bulk-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
}
.bulk-not-found-icon {
  font-size: 26px;
  color: var(--blue-color);
}
.init-exl-wrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3b8ad81a;
  /* box-shadow: 0px 0px 5px 0px #ff000026; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.exp-btn {
  background: var(--blue-color);
}
.sample-btn {
  background: #808080b3;
  border: unset;
}
.number-btn {
  position: absolute;
  top: 7px;
  right: 7px;
  background: var(--blue-color);
  color: white;
  border-radius: 3px;
  padding: 6px 14px;
}
.add-number-wrap {
  position: relative;
}
.search-number-input {
  height: 35px;
}
.c-btn:disabled {
  /* Add your styles for the disabled button here */
  background-color: gray;
  color: white;
  cursor: not-allowed;
}
.c-btn-remove {
  background: #ffffff;
  color: rgba(178, 34, 34, 0.815);
  border: 1px solid #b2222236;
}

/* faq bot style */
.faq-recipient-avatar {
  width: 45px;
  height: 45px;
}
.faq-recipient-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  object-fit: contain;
  background: unset;
  box-shadow: var(--brand-shadow);
  border: unset;
}

/* rich content */
.article-body {
  width: 100%;
}
.article-body p {
  font-size: 12px;
  line-height: 17px;
  color: #000 !important;
}
.article-body img {
  width: 100%;
}
.article-body p strong {
}
.article-body ul {
}
.article-body ul li {
  margin: unset;
  display: unset;
}
.article-body ul li a {
}
.article-body ul li em {
}
.article-body ul li span {
}
.article-body ul li u {
}
.article-body ul li p.wysiwyg-indent4 {
}
.article-body ul li span.wysiwyg-color-black {
}
.article-body ul li a.article-list-link {
}
.article-body p.wysiwyg-indent4 {
}
.article-body p.wysiwyg-indent6 {
}
.article-body p.wysiwyg-indent7 {
}
.article-body ul li a.article-list-link {
}
.faq-bubble-agent {
  font-family: var(--font-montserrat);
  font-size: 14px;
}
.faq-bubble-visitor {
  margin: 10px 0px !important;
  margin-top: unset !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 13px;
  padding: 10px 12px;
}
.faq-bubble-wrap p {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
.faq-bubble-wrap h2 {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
}
.faq-bubble-wrap ul {
  margin: 10px 15px;
}
.faq-bubble-wrap ul li {
  display: block;
  margin: unset;
  line-height: 21px;
  color: #000;
}
.faq-bubble-wrap br {
  display: block;
  margin-bottom: 2px;
  font-size: 2px;
  line-height: 2px;
}
.faq-message-body ul li {
  margin-bottom: 3px;
  font-size: 13px;
  line-height: 20px;
}
.message-bubble-filled {
  background: var(--bg) !important;
  color: var(--paragraph-color) !important;
  border-top-left-radius: 0px !important;
  border: 1px solid var(--bg) !important;
}
.faq-select-wrap {
}
.faq-select-wrap input {
  color: black !important;
}
/* .faq-select-wrap .css-tj5bde-Svg {
  width: 10px;
  color: white;
  fill: white;
}
.faq-select-wrap .css-14el2xx-placeholder{
  color: white;
} */
.language-select-box {
  font-size: 12px;
  background: unset;
  border: 1px solid #ffffff75 !important;
  color: white;
  padding: 6px 4px;
}
.language-select-box option {
  background: #5c7287;
  color: white;
  border-bottom: 1px solid white;
}

.faq-curosol-agent {
}
.faq-swiper-container {
  margin-bottom: unset !important;
}
.date-bubble-item {
  margin-bottom: 22px !important;
}
.header-nav {
  margin-right: 30px;
}
.header-nav ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
}
.header-nav ul a li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.header-nav ul a li span {
  font-size: 14px;
  color: var(--gray-color);
  font-weight: 400;
}
.header-nav ul a li svg {
  font-size: var(--title-size);
  color: var(--gray-color);
}
.plain-header-logo {
  width: 100%;
  height: 35px;
}
.plain-header-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.middle-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg);
}
.bot-select-wrap {
  margin: 15px 20px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  border: 2px solid #d3d3d3e0;
  box-shadow: 1px 2px 4px 0px #d3d3d382;
}
.bot-avatar {
  width: 45px;
  height: 45px;
  overflow: hidden;
}
.bot-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px lightgray;
  border: 3px solid #f7f7f7;
}
.bot-select {
  width: 100%;
}
.bot-select select {
  padding: unset;
  border: unset;
  background: unset;
  margin-top: unset;
  font-weight: 600;
  font-size: 16px;
  margin-left: -5px;
}
.bot-select p {
  font-size: 12px;
  font-weight: 500;
  color: #80808085;
  line-height: 15px;
}
.bot-select option {
}
.alart-error {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  align-items: center;
  border: 1px solid hsl(11deg 100% 37.8% / 29%);
  padding: 20px 30px;
  border-radius: 6px;
  background: white;
  margin-top: 20px;
}
.alart-icon svg {
  font-size: 30px;
  color: var(--danger-color);
}
.alart-content {
  max-width: 80%;
}
.alart-content h2 {
  font-size: 20px;
  color: var(--danger-color);
}
.alart-content p {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 500;
}
.alert-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffe106;
  color: black;
  border-radius: 5px;
  margin-top: 5px;
}
.alert-content p {
  color: black;
}
.alert-content a {
  text-decoration-line: underline;
  text-decoration-color: #fd0101;
  font-weight: bold;
}
.embadded-form {
  position: relative;
}
.embadded-form input {
  background: white;
  padding: 15px;
  box-shadow: 0px 1px 0px 0px #d3d3d357;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  font-size: var(--paragraph-size);
}
.embadded-btn {
  display: flex;
  justify-content: flex-start;
  grid-gap: 4px;
  align-items: center;
  background: var(--brand-color);
  border-radius: 5px;
  padding: 9px 13px;
  position: absolute;
  top: 19px;
  right: 10px;
  transition: all 0.3s ease-in-out;
}
.embadded-btn:hover {
  background: var(--secondary-color);
}
.embadded-btn svg,
.embadded-btn span {
  color: white;
  font-weight: 600;
}
.embadded-code {
  margin-top: 20px;
}
.embadded-title {
  margin-top: 30px;
}

.embadded-title p {
  font-size: 14px;
}
/* create chatbot */
.create-chatbot-container {
  width: 100%;
  min-height: 560px;
  max-height: 585px;
  background: #fbfbfb;
  padding: 40px;
  position: relative;
}
.chatbot-steps-wrapper {
  margin-bottom: 20px;
}
.create-chatbot-file-body {
  min-height: 400px;
  max-height: 475px;
  padding-right: 58px;
}
.create-bot-scroll {
  overflow-y: scroll;
}
/* recent chat scrollbar width */
.create-chatbot-file-body::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.create-chatbot-file-body::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.create-chatbot-file-body::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.create-chatbot-file-body::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.create-chatbot-container::after {
  content: '';
  position: absolute;
  right: 445px;
  top: 0px;
  height: 100%;
  /* width: 1px; */
  background: #d9d9d9;
}
.create-chatbot-file-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 3fr;
  grid-gap: 110px;
}
.create-title {
  margin-bottom: 5px;
  text-align: left;
}
.create-title p {
  font-size: var(--p-size);
}
.create-title h2 {
  font-size: var(--title-size);
  font-weight: 600;
}
.create-chatbot-steps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background: #35a9891f;
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
}
.create-chatbot-step-item {
  cursor: pointer;
  padding: 0px 15px;
  color: #000000;
  font-size: var(--mini-size);
  height: 42px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.create-chatbot-step-item.react-tabs__tab--selected {
  background: #009688;
  color: white;
  box-shadow: 0px 2px 2px -1px #8d8d8d5e;
  font-weight: 600;
  border-radius: 5px;
}
.create-chatbot-step-item.react-tabs__tab--selected:hover {
  background: #009687dc;
  color: white;
}
.chatbot-upload-image-wrap {
  /* background: white; */
  cursor: pointer;
  width: 80% !important;
}
.chatbot-upload-content-wrap {
  display: flex;
  border: 2px dotted lightgray;
  border-radius: 5px;
  padding: 35px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 7px;
  cursor: pointer;
  background: white;
}
.chatbot-upload-content-wrap h3 {
  font-size: var(--title-size);
  color: dimgray;
  font-weight: 600;
}
.chatbot-upload-content-wrap p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.chatbot-upload-content-wrap svg {
  font-size: 30px;
}
.avatar-template-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid lightgray;
  max-height: 200px;
  overflow-y: scroll;
}
.chatbot-action-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-top: 50px;
}
.chatbot-action-item {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2px;
  background: #8080800d;
  padding: 7px 20px;
  border-radius: 5px;
}
.chatbot-action-item svg {
  color: var(--brand-color);
}
.chatbot-action-item.active {
  background: var(--brand-color);
  color: white;
  box-shadow: 0px 2px 2px -1px #8d8d8d5e;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 12px;
  font-size: var(--mini-size);
}
.prompt-second-title {
  margin-top: 20px;
}
.chatbot-action-item.active:hover {
  background: var(--brand-color-bold);
}
.create-chatbot-input-wrap {
  text-align: left;
}
.title-label {
  color: gray !important;
  font-weight: 500;
  font-size: 14px !important;
}
.or {
  background: #d3d3d34d;
  width: 100%;
  height: 2px;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.or span {
  background: white;
  padding: 0px 10px;
  color: gray;
  font-weight: 600;
}

/* uploaded files */
.uploaded-file-wrap {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 200px;
  overflow-y: scroll;
  padding-right: 5px;
}

/* scrollbar design */
/* width */
.added-link-item {
  min-height: 410px;
}
.scrollbar-hide {
  overflow: unset;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #88888849;
  border-radius: 20px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.uploaded-file-item {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  grid-gap: 15px;
}
.uploaded-file-action {
  display: flex;
  justify-content: flex-end;
}
.uploaded-file-item.selected-item {
  border: 1px dotted var(--brand-color);
}
.selected-item .uploaded-file-icon-and-name {
}
.selected-item .uploaded-file-icon-and-name span,
.selected-item .uploaded-file-icon-and-name svg {
  color: var(--brand-color);
}
.selected-icon {
  /* position: absolute;
  left: 10px;
  top: 0px; */
  color: #7a7f9a;
  height: 100%;
  font-size: 22px;
  color: var(--brand-color) !important;
}
.chatlog-search-icon {
  position: absolute;
  left: 10px;
  top: 0px;
}
.uploaded-file-icon-and-name {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 8px;
  text-align: left;
  width: 100%;
  flex-direction: column;
  padding: 5px;
}
.uploaded-file-icon-and-name span,
.uploaded-file-icon-and-name svg {
  color: #a5a5a5;
  word-break: break-all;
  /* width: 100%; */
}
.uploaded-file-icon-and-name span {
  width: 100%;
  line-height: 24px;
}
.uploaded-file-action svg {
  color: #a5a5a5;
}
.uploaded-label {
  text-align: left;
  font-size: 13px;
  color: gray;
  margin-bottom: 2px;
}
.sub-ancor {
  padding: 3px 20px !important;
}
.sub-lable {
  font-size: 11px;
}

.chatbot-config-wrapper {
}
.chatbot-config-fields {
}
.chatbot-config-bot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbot-config-widget-wrapper {
  /* position: fixed;
  bottom: 0px;
  right: 50px; */
}
.config-bot-widget {
  z-index: 9 !important;
}

/* list of chatbots */
.list-of-the-bot-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.list-of-the-bot-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
}
.list-of-the-bot-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 7px;
  background: var(--bg-light);
  margin: 10px 0px;
  padding: 20px 30px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 0px var(--border-color);
  width: 100%;
  border: 1px solid var(--border-color);
}
.list-bot-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.list-bot-logo {
  background: var(--white-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid var(--border-color);
}
.list-bot-logo.no-bot {
  background: var(--brand-color) !important;
}
.list-bot-logo svg {
  color: var(--bg-light);
  font-size: 30px;
}
.list-bot-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.list-bot-content {
}
.list-bot-content h3 {
  font-size: var(--title-size);
}
.list-bot-option-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
}
.list-span-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 7px;
  cursor: pointer;
}
.list-span-flex span {
  font-weight: 500;
  font-size: var(--p-size);
}
.billing-wrapper {
  padding: 20px;
  background: var(--bg-light);
  height: 100%;
}
.underline {
  width: 100%;
  height: 3px;
  background: #e9e9e959;
  margin: 10px 0px;
}

/* billing */
.billing-wrapper {
  padding: 50px 20px;
}
.billing-cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  margin: 40px 0px;
}
.billing-card {
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 7px;
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
}
.billing-content {
}
.billing-card svg {
  font-size: 30px;
  color: var(--brand-color);
}
.billing-btn-wrap {
  margin: 20px 0px;
}
.outline-button {
  background: var(--bg-light);
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
}
.outline-button svg,
.outline-button span {
  color: var(--brand-color);
}
.new-header-logo-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  text-transform: capitalize;
  border-left: 2px solid #d3d3d32b;
  padding-left: 15px;
  padding-right: 15px;
}
.color-title {
  /* font-size: 20px; */
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}
.color-title span {
  color: black;
  font-weight: 500;
}
.chrome-picker {
  margin-top: 10px !important;
}
.bot-config-header,
.chatbot-config-footer-btn,
.chatbot-config-bubble-user {
  background: var(--brand-color);
}
.chatbot-config-emoji svg {
  color: var(--brand-color);
}
.botchat-demo-wrapper {
  position: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  grid-gap: 30px;
  width: 100%;
  margin-bottom: 70px;
}
.bot-demo-widget {
  position: unset !important;
}
.suggest-links {
  margin-top: 20px;
}
.loading-wrap {
  height: 22px;
  width: 70px;
}
.selected-color {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
.brand-color-selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  font-weight: 500;
  color: #000;
}
.setting-panel {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
}
.setting-menu-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  border-bottom: 2px solid #d3d3d340;
}
.setting-menu-item {
  color: #939393;
  /* border-bottom: 2px solid var(--brand-color); */
  padding: 10px 20px;
  font-size: var(--title-size);
  font-weight: 500;
  background: unset;
  cursor: pointer;
}
.setting-menu-item.react-tabs__tab--selected {
  color: var(--brand-color);
  border-bottom: 2px solid var(--brand-color);
}
.prompt-body-wrapper {
  grid-gap: 40px;
}
.prompt-wrapper {
  margin-top: 20px;
  height: 370px;
  padding-right: 10px;
  width: 350px;
  max-width: 350px;
}

.prompt-action-wrap {
  margin-top: 15px;
  justify-content: flex-start;
}
.created-prompt-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  grid-gap: 10px;
}
.created-prompt-delete-icon {
  color: var(--danger-color) !important;
}
.chat-not-found {
  background: #f7bda733;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.bot-delete-icon {
}
.bot-delete-icon svg {
  color: #9e9e9e;
  font-size: 25px;
}
.list-of-the-bot-item:hover .bot-delete-icon svg {
  visibility: visible;
  opacity: 1;
}

.list-of-the-bot-item.active {
  border-left: 3px solid var(--brand-color);
}
.currently-selected-badge {
  font-size: 13px;
  color: var(--brand-color);
}

.bot-launch-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  flex-direction: column;
  text-align: center;
  width: 400px;
}
.bot-launch-wrap h3 {
  font-size: 20px;
  color: #667085;
}
.bot-launch-btn {
  background: var(--brand-color);
  padding: 10px 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  color: white;
  font-size: 19px;
  margin: auto;
  font-weight: 500;
}
.bot-launch-btn svg {
  color: white;
  font-size: 30px;
}
.text-and-date-wrap {
  position: relative;
  /* float: right;
  width: fit-content; */
}
.chatlog-user-agent {
  /* margin: 0px !important; */
}

.date-badge {
  font-size: 10px;
  /* display: contents; */
  position: absolute;
  bottom: -70px;
  right: 0px;
}
.text-and-date-wrap .date-badge-bot {
  left: 0px;
  bottom: -20px;
}
.chat-list-sub-content {
  display: flex;
  width: max-content;
  grid-gap: 6px;
  align-items: center;
  justify-content: flex-start;
}
.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgba(204, 204, 204, 0.486);
}
.chat-filter-wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 6px;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
}
.chat-filter-icon-and-text-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 6px;
  align-items: center;
  cursor: pointer;
}
.phone-input-group {
}
.PhoneInput {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0px;
}
.PhoneInputCountrySelect {
  width: 170px;
}
.PhoneInputCountryIconImg {
  width: 40px;
}
.PhoneInputCountry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}

.change-email-phone {
  margin: 10px 0px;
}
.change-email-phone p {
  color: var(--brand-color);
  border-bottom: 1px solid #00968840;
  font-size: 14px;
  width: max-content;
  margin-bottom: 10px;
  cursor: pointer;
}
/* email change model */
.email-change-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(0 0 0 / 40%);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.email-change-modal {
  background: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px lightgray;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 15px;
  flex-direction: column;
  min-width: 0px;
  transition: min-width 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  width: 0px;
}
.email-change-modal.active {
  min-width: 480px;
  visibility: visible;
  opacity: 1;
}
.model-title {
}
.model-title h2 {
  font-size: 20px;
  color: #6a6a6a;
  font-weight: 600;
}
.email-change-inputs {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  grid-gap: 25px;
  width: 100%;
  flex-direction: column;
}
.email-change-inputs input {
  border: 1px solid #d3d3d3b6 !important;
  background: white;
}
.email-change-inputs input:focus {
  border: 1px solid var(--brand-color);
}
.change-button-wrap {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  grid-gap: 10px;
  width: 100%;
}
.default-button {
  padding: 10px 16px;
  border-radius: 5px;
  background: var(--brand-color);
  color: var(--white-color);
  font-size: var(--mini-size);
  font-weight: 500;
  transition: all 0.2s ease-in;
}
.default-button:hover {
  background: var(--brand-color-bold);
}
.button-primary {
}
.button-danger {
  color: var(--danger-color);
  background: var(--gray-color-2);
}
.dropdown-wrapper {
  /* position: absolute; */
  top: 50px;
  right: 0px;
  width: -moz-fit-content;
  /* width: fit-content; */
  border-radius: 5px;
  /* background: white; */
  /* min-width: 200px; */
  /* box-shadow: 0px 0px 5px 0px lightgray; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 8px;
  cursor: pointer;
}
.dropdown-wrapper p {
  width: max-content;
}
/* .dropdown-wrapper::before {
  width: 30px;
  height: 30px;
  content: '';
  position: absolute;
  top: 0px;
  right: 6px;
  background: #ada7a7;
  transform: rotate(45deg);
  z-index: -1;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px lightgray;
} */
.dropdown-wrapper ul {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.dropdown-wrapper li {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid #d3d3d359;
}
.relative {
  position: relative;
}
.verify-btn {
  margin-top: 10px;
}
.verify-svg {
  color: var(--brand-color);
  font-size: 15px;
}
.verify-fail-svg,
.verify-fail-heading {
  color: var(--danger-color) !important;
}
.verify-fail-btn {
  background: var(--danger-color);
}
.verify-fail-wrap {
  border: 1px solid var(--danger-color);
}
.prompt-bot-select-container {
  width: 100%;
  background: var(--bg-light);
}
.select-bot-wrap {
  width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  height: 125px;
  padding-left: 50px;
  padding-top: 20px;
}

/* landing page */
.landing-page-canvas {
  background: var(--bg);
}
.landing-container {
  max-width: 80%;
  margin: 30px auto;
}
.landing-header-wrapper {
  width: 100%;
  max-width: 100% !important;
  background: white;
  padding: 10px 50px;
  /* margin-bottom: 30px; */
  position: sticky;
  top: 0px;
  box-shadow: 0px 2px 7px 2px #d3d3d352;
  z-index: 999;
}
.desktop-header-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.01s ease-in-out;
}
.landing-page-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 25px;
}
.landing-page-menu li {
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

/* footer */
.footer-menu-wrap {
  grid-gap: 35px;
}
.footer-menu-wrap li::before {
  content: '';
  position: absolute;
  right: -24px;
  top: 10px;
  width: 10px;
  height: 10px;
  background: #e1e1e1;
  border-radius: 20px;
}
.footer-menu-wrap li:last-child::before {
  content: unset;
  position: unset;
  width: unset;
  height: unset;
}
.landing-page-menu li img {
  width: 65px;
  height: auto;
}
.landing-page-menu a:hover {
  color: var(--brand-color);
}
.landing-header-left {
}
.landing-header-right {
}
.landing-hero-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: center;
  padding-top: 30px;
}
.landing-hero-content h2 {
  font-size: var(--heading-three);
}
.landing-hero-content h1 {
  margin-bottom: 6px;
  font-size: 38px;
  font-weight: 800;
}
.landing-hero-action {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-top: 20px;
  width: fit-content;
  text-align: center;
}
.landing-hero-action-btn {
  background: var(--brand-color);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: var(--p-size);
  font-weight: 500;
  border: 0px solid transparent;
  width: max-content;
}
.landing-hero-action-btn:hover {
  background: #061410;
}
.landing-hero-action span {
  font-size: 14px;
  color: #000000;
}
.landing-hero-thumb {
  width: 100%;
  height: -webkit-fill-available;
}
.landing-hero-thumb img {
  width: 100%;
  height: 100%;
}
.brand-color {
  color: var(--brand-color);
  /* text-transform: uppercase; */
}
/* steps section */
.landing-page-steps {
}
.landing-title {
  margin: 40px 0px;
  text-align: center;
  grid-gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.landing-title h2,
.landing-title h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 43.5px;
}
.landing-title p {
  max-width: 60%;
  margin: auto;
}
.landing-step-ss {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  /* margin-top: 40px; */
  /* margin-bottom: 60px; */
  background: var(--bg);
  padding: 40px;
  border-radius: 20px;
}
.landing-ss-item {
  width: 100%;
  height: auto;
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px lightgray;
}
.landing-ss-item p {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
}

.landing-ss-item span {
}
.ss-thumb {
  height: 100%;
  width: 100%;
}
.landing-ss-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}
.step-button {
  background: var(--brand-color);
  border-radius: 30px;
  padding: 10px 20px;
  color: var(--white-color);
  font-size: var(--title-size);
  font-weight: 500;
}
.link-badge,
.design-badge,
.publish-badge {
  font-weight: 500;
}
.link-badge {
  color: #ff0303;
  border-bottom: 1px solid #ff0303;
}
.design-badge {
  color: #c5b30a;
  border-bottom: 1px solid #c5b30a;
}
.publish-badge {
  color: #14af2d;
  border-bottom: 1px solid #14af2d;
}
/* video */
.video-demo-wrapper {
  max-width: 60%;
  margin: auto;
}
.video-demo-wrapper h2 {
  font-size: 36px;
  text-align: center;
  line-height: 43.5px;
  font-weight: 600;
}
.video-demo-wrapper iframe {
  border-radius: 5px;
  margin-top: 20px;
}

/* call to action */
.call-to-action-wrapper {
  background: var(--bg-light);
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0px 0px 5px 0px lightgray;
  max-width: 75%;
  margin: auto;
}
.call-to-action-wrapper .landing-hero-content {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.call-to-action-wrapper .landing-hero-action {
  margin-top: 20px;
  margin: 20px auto;
}
.call-to-action-wrapper .landing-hero-action button {
  padding: 11px 22px;
  font-size: 16px;
  font-weight: 600;
}
.contact-us-content {
  margin: 50px 0px !important;
}
/* accordion */
.landing-title-center .landing-hero-content {
  text-align: center;
  margin-bottom: 20px;
}
.landing-accordion-wrapper {
  margin-top: 20px;
}
.accordion-container {
  max-width: 60%;
  margin: 50px auto;
}
.accordion-wrapper.open {
  background: white;
}

/* footer */
.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  flex-direction: column;
  background: white;
  padding: 30px;
  border-top: 2px solid #d3d3d324;
}
.footer-logo {
  width: 80px;
  height: 100%;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  margin-left: -10px;
}
.footer-menu {
}
.footer-menu .landing-page-menu li a {
  font-weight: 600;
  font-size: 16px;
}
.footer-bottom-info p {
  font-size: 16px;
}
.sign-in-btn {
  border: 1px solid var(--brand-color);
  border-radius: 5px;
  background: white;
  padding: 10px 20px;
  color: var(--brand-color);
}

/* pricing */
.pricing-container {
  margin-top: unset;
}
.pricing-container .landing-title {
  margin-top: unset;
  padding-top: 40px;
}

.toggle-menu .ant-tabs-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 50px;
  background: #80808024;
  width: max-content;
  margin: auto;
  border-radius: 10px;
}
.toggle-menu .ant-tabs-nav-wrap {
  width: unset;
}
.toggle-menu .ant-tabs-tab {
  border: unset !important;
}
.toggle-menu .ant-tabs-tab.ant-tabs-tab-active {
  border-radius: 10px;
}
.toggle-menu .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: unset;
}
.toggle-menu a {
  font-weight: 600;
}
.pricing-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 20px;
  margin: 30px auto;
}
.pricing-item {
  box-shadow: 0px 0px 8px 0px #d3d3d385;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #d3d3d308;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  flex-direction: column;
  width: 100%;
  min-height: 540px;
  max-width: 380px;
  background: var(--white-color);
  transition: all 0.3s ease-in;
}
.pricing-item:hover {
  box-shadow: 0px 4px 3px 0px lightgray;
}
.addon-item {
  min-height: auto;
  position: relative;
  max-width: 400px;
  margin: auto;
  border-radius: 20px;
  background: var(--white-color);
}
.addon-wrapper {
  flex-direction: column;
  padding-bottom: 50px;
  margin-bottom: unset;
}
.addon-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #00968829;
  color: #009688;
  border-radius: 20px;
  padding: 3px 15px;
  font-weight: 600;
  font-size: 13px;
}
.pricing-content {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
}
.pricing-lists {
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}
.addon-details {
  text-align: center;
}
.addon-body {
  min-height: 110px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 15px;
  align-items: center;
  width: 100%;
}
.pricing-lists li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  font-size: var(--mini-size);
  font-weight: 400;
  font-family: var(--font-poppins);
}
.pricing-lists li span {
}
.pricing-dot {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: #ccc;
  margin-top: 8px;
}
.pricing-title {
  width: 100%;
  text-align: center;
}
.pricing-title p {
  font-size: var(--p-size);
}
.pricing-name {
  font-size: 25px;
  font-weight: 600;
  max-width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--heading-color);
  line-height: 33px;
}
.pricing-action {
  text-align: center;
  width: 100%;
}
.pricing-price {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}
.price-amount-badge {
  font-size: 28px;
  font-weight: 700;
}
.addon-details p {
  font-size: var(--mini-size);
}

.spacing {
  padding-top: 30px;
}
.header-list-logo {
  margin-right: 30px;
}

/* pages */
.page-details-container {
}
.page-details-meta {
  text-align: center;
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 8px;
}
.page-details-meta h1 {
}
.page-details-meta p {
}
.page-details-content {
}
.page-details-content h2 {
  margin-bottom: 15px;
  font-size: 27px;
}
.page-details-content p {
  margin-bottom: 10px;
  color: var(--heading-color);
}
.page-details-content a {
}
.page-details-content-block {
  margin-bottom: 20px;
}
.page-details-content-block:last-child {
  margin-bottom: 40px;
}
.page-details-content-block h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.landing-hero-content p {
  color: var(--heading-color);
  line-height: 30px;
  font-size: 16px;
}
.pricing-plan-type {
  font-weight: 500;
  cursor: pointer;
  padding: 12px 20px;
  transition: all 0.3s ease-in-out;
}
.pricing-plan-type.selected {
  background: var(--brand-color);
  border-radius: 10px;
  color: white;
  padding: 12px 20px;
  font-weight: 500;
}
.addon-select {
  padding: unset;
  width: auto;
  margin: 0px 7px;
  font-size: 14px;
}
.hero-content h1 {
  font-size: 42px;
}
.bold {
  font-weight: 700;
  color: #000;
}
.landing-header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 40px;
}
.action-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
}
.landing-header-toggle svg {
  font-size: 35px;
}
.botdemo-menu-option-item {
  color: white;
  padding: 1px 8px;
  display: inline-block;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  font-family: var(--poppins);
  background: var(--brand-color);
}
.suggest-link {
  width: 100%;
  margin: 60px auto;
  text-align: center;
  display: flex;
  justify-content: flex-start;
}
.suggest-link a {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--brand-color);
}
.suggest-link a svg {
  color: var(--brand-color);
}

/* bot upgrate */
.bot-upgrate-alart {
  padding: 10px 25px;
  border-radius: 8px;
  color: white;
  background: #ffe106;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 30px; */
  grid-gap: 10px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  margin: 15px 0px;
}
.bot-upgrate-alart a {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 30px; */
  grid-gap: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}
.bot-upgrate-alart a:hover {
}

.bot-upgrate-alart:hover {
  color: unset;
  /* background: unset; */
}
.bot-alart-cancel-icon {
  width: 18px !important;
  height: 18px !important;
}
.bot-upgrate-alart svg {
  font-size: 10px;
  width: 7px;
  height: 7px;
  fill: #000000;
}
.payment-status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.payment-status-wrap {
  border: unset;
  box-shadow: 0px 0px 4px 0px lightgray;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  width: 350px;
}
.payment-status-action {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-status-title p {
  color: var(--gray-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.payment-status-title h2 {
  color: var(--brand-color) !important;
}
.payment-status-title svg {
  font-size: 45px;
  border: 3px solid #00968824;
  border-radius: 50%;
}
.payement-btn {
  background: var(--brand-color);
}

/* payment fail */
.payment-status-fail-title {
}
.payment-status-fail-title p {
  color: var(--gray-color);
}
.payment-status-fail-title h2 {
  color: var(--danger-color) !important;
}
.payment-status-fail-title svg {
  color: var(--danger-color);
  border: 3px solid hsl(11deg 100% 37.8% / 16%);
}
.payment-btn-fail {
  background: var(--danger-color);
}
.upgrate-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--brand-color);
  border-radius: 15px;
  padding: 5px 15px;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  grid-gap: 6px;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
.upgrate-btn svg {
  width: 18px;
  fill: white;
  color: white;
}
.upgrate-btn span {
  font-weight: 500;
  font-size: var(--mini-size);
}
.upgrate-progress {
}

.upgrate-progress p {
  width: max-content;
  font-size: var(--p-size);
  font-weight: 500;
}

/* iframe design */
.chatbot-iframe-widget {
  background: var(--bg-light);
  background-color: transparent;
  border-radius: 8px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  box-shadow: 0px 1px 3px 0px lightgray;
  height: 0px;
  width: 372px;
  overflow: hidden;
  /* overflow: auto; */
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: relative;
  z-index: -1;
  overscroll-behavior: contain;
  /* display: none; */
  border-radius: 10px;
}
.chatbot-iframe-widget.active-iframe {
  visibility: visible;
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: block;
  overflow: unset;
  /* border: 1px solid #009688; */
  border-radius: 8px;
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -1px 2px 6px 0px lightgray !important;
  /* border: 1px solid #d3d3d330; */
}
.chatbot-iframe-widget-header {
  padding: 10px 25px;
  border-radius: 8px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.chatbot-iframe-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.chatbot-iframe-left h3,
.chatbot-iframe-left p {
  color: var(--white-color) !important;
}
.chatbot-iframe-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  flex-direction: column;
  position: relative;
}
.chatbot-iframe-right svg {
  color: var(--white-color) !important;
  fill: var(--white-color) !important;
}
.bot-message-wrapper-iframe {
  padding: 0px 15px;
  width: 100%;
  overflow: hidden auto;
  background: var(--bg-light);
  transition: all 0.3s ease 0s;
  flex: 0 1 auto;
  scrollbar-width: thin;
  position: relative;
  /* max-height: 390px; */
  min-height: 160px;
  height: 100%;
  padding-bottom: 117px !important;
}
.chatbot-iframe-body {
  position: relative;
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 30px; */
  float: left;
  padding: 5px !important;
}
.logo-and-text-wrap {
  display: flex;
  width: 100%;
}
.chatbot-iframe-footer-container {
  position: absolute;
  z-index: 9999;
  bottom: -8px;
  width: 100%;
  right: 0px;
  left: 0px;
}
/* new input for aceva bot */
.aceva-gpt-input {
  overflow-wrap: break-word;
  height: 45px;
  max-height: 140px;
  border-radius: 8px;
  line-height: 22px;
  box-sizing: border-box;
  width: 100%;
}
.chatbot-iframe-suggest-buttons {
  display: flex;
  justify-content: flex-start;
  /* flex-flow: wrap; */
  grid-gap: 5px;
  margin-bottom: 5px;
  flex-flow: wrap;
  overflow-x: auto;
  /* position: absolute;
  bottom: 60px; */
  margin: unset;
  padding: 10px 10px;
  width: 100%;
  background: white;
  border-top: 1px solid #d3d3d31f;
}
.chatbot-iframe-footer-wrap {
  width: 100%;
  /* height: 40px; */
  position: relative;
}
.chatbot-iframe-footer-wrap form input {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 10px 15px !important;
  font-size: 15px;
  font-weight: 400;
}
.chatbot-iframe-ft-btn {
  right: 12px;
}
.chatbot-iframe-ft-btn {
  color: var(--white-color);
}
.bubble-iframe-agent {
  color: #000;
  background: var(--bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: 280px;
  width: fit-content;
  font-size: 14px !important;
  line-height: 22px;
  padding: 8px 8px;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  /* max-width: 85%; */
  margin-bottom: 10px !important;
}
.bubble-iframe-agent-wrap {
  color: #000;
  background: var(--bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: 280px;
  width: -moz-fit-content;
  font-size: 14px !important;
  line-height: 22px;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  /* max-width: 85%; */
  padding: 5px 8px !important;
  width: 100%;
}
.welcome-message-wrap p {
  font-size: 14px;
  line-height: 20px;
  padding: 0px 5px;
  color: black;
}
.chatbot-iframe-ft-btn {
  cursor: pointer;
}
.bubble-iframe-agent-wrap p {
  padding: 5px 8px !important;
  width: 100%;
  word-wrap: break-word;
}
.bubble-iframe-typing {
}
.bubble-iframe-p {
  padding: unset !important;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.bubble-iframe-p-user {
  color: white;
}
.chatbot-config-footer-btn {
  right: 4px;
}
.message-time-left,
.bot-date-response-badge {
  left: 25px;
}
.typing-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
}
.bot-iframe-footer {
  position: unset;
}

.toggle-bot-icon {
  cursor: pointer;
  width: 70px;
  height: 70px;
  margin: 50px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 3px 0px lightgray;
}
.company-name {
  font-weight: 500;
  font-size: 11px;
}

/* video demo tut */
.demo-video-tut {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  border-left: 2px solid lightgray;
  padding-left: 10px;
  font-size: var(--p-size);
  font-weight: 500;
  color: var(--brand-color);
}
.demo-video-tut-icon {
  color: #cd201f;
}
.tut-demo-model-container {
  display: none;
  visibility: hidden;
  opacity: 0;
  /* transition: all 0.3s ease-in-out; */
  height: 0px;
}
.tut-demo-model-container.active {
  width: 100%;
  height: auto;
  position: fixed;
  inset: 0;
  background: #00000075;
  visibility: visible;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.demo-video-modal-wrapper {
  background: white;
  width: 800px;
  padding: 25px 40px;
  border-radius: 7px;
  position: relative;
}
.demo-video-modal-wrapper iframe {
  border-radius: 5px;
}
.cancel {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999999;
  /* background: red; */
  padding: 2px;
  font-size: 35px;
  color: black;
  border-radius: 58px;
}
.fullscreen-image {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.fullscreen-image img {
}
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9999999;
  background: red;
  padding: 7px 20px;
  font-size: 17px;
  color: white;
  font-weight: 500;
  border-radius: 58px;
}

.fullscreen-image-overlay {
  width: 100%;
  height: 100%;
}
.fullscreen-image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.login-with-google-icon {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  box-shadow: 0px 4px 6px -2px #d3d3d3a8;
  border-radius: var(--radius);
  padding: 10px;
  cursor: pointer;
  border: 1px solid #d3d3d370;
}
.login-with-google-icon img {
  width: 20px;
}
.login-with-google-icon span {
  font-weight: 500;
}
.forgot-password-label span a {
  color: var(--brand-color);
  font-weight: 400;
}
.excl-icon {
  font-size: 15px;
}

.mobile-sidebar-wrapper {
  width: 100%;
  background: #00000069;
  /* height: 100vh;
  position: fixed; */
}
.bot-action-wrap {
  display: flex;
  grid-gap: 25px;
}
.max-content {
  width: max-content;
}

.menu-list-text-item {
}
.menu-list-text-item.active {
  color: var(--brand-color);
}

/* Add a class to style the sticky header */
.desktop-header-menu-wrapper.active-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white; /* You can customize the background color */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  left: 0px;
  right: 0px;
  max-width: 100%;
  border-radius: unset;
  padding: 10px 130px;
}
.full-width-container {
  background: var(--white-color);
  margin: auto;
  padding: 20px 0px;
}
.cross {
  color: darkred;
}
.h-100 {
  height: 100vh;
}
.cancel-icon,
.search-btn {
  position: absolute;
  top: 0px;
  height: 100%;
  font-size: 22px;
  color: grey !important;
  right: 0px;
  transition: all 0.3s ease-in-out;
}
.search-btn {
  font-size: 13px;
  font-weight: 500;
  color: var(--brand-color) !important;
  /* top: 7px; */
  /* line-height: 0px; */
  cursor: pointer;
}

.cancel-icon {
  right: 50px;
}
.select-all-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  border-right: 2px solid #f7f7f7;
  padding-right: 10px;
}
.select-all-wrap p {
  font-size: 14px;
  font-weight: 500;
  color: var(--p-light-color);
}
.select-all-icon {
  position: unset;
}
.search-box-wrap {
  display: flex;
  align-items: center;
  background: white;
  padding: 0 10px;
  margin-top: 10px;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 10px;
}
.chat-filter-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px;
}
.export-btn {
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #d3d3d385;
  border-radius: 5px;
  padding: 1px 10px;
  cursor: pointer;
}
.chat-search-input {
  border: unset !important;
}
.chat-filter-opt {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
}
.date-filter-model-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #00000085;
  inset: 0;
  top: 50px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.date-filter-model-wrapper.active {
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  top: 0px;
}
.date-filter-model {
  width: 350px;
  padding: 30px;
  border-radius: 5px;
  background: white;
  position: relative;
}
.add-link-model {
  width: 80%;
  border-radius: 5px;
  background: #fbfbfb;
  position: relative;
}
.prompt-edit-model {
  width: 530px;
  padding: 45px;
}
.chat-model-cancel-icon {
  top: -105px;
  right: 10px !important;
  font-size: 31px;
  z-index: 9999;
}
.addlink-modal-cancel-icon {
  float: right;
  margin: -25px;
  font-size: 30px;
  color: grey !important;
  transition: all 0.3s ease-in-out;
}
.select-icon {
  font-size: 17px !important;
}
.header-wrap {
  max-width: 80%;
  margin: auto;
}
.step-content-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.brand-big-title {
  font-weight: 700;
  color: var(--brand-color);
  font-size: 36px;
  text-transform: uppercase;
  line-height: 43.5px;
}
.landing-full-title {
  width: 100%;
  background: white;
  padding: 50px 0px;
  padding-top: unset;
}

.our-process-steps-section {
  background: var(--white-color);
  padding-bottom: 5px;
  padding: 50px 0px;
}
.step-container {
  background: var(--white-color);
}
.video-section {
  background: var(--bg);
}
.live-demo-section {
  background: var(--white-color);
  padding: 50px 0px;
}
.call-to-action-section {
  padding: 80px 0px;
}
.faq-section {
  background: var(--white-color);
  padding: 50px 0px;
}
.right-arr-icon {
  width: 25px;
}
.hero-container {
  margin: 0px auto;
}
.register-thumb {
  width: 100%;
  height: auto;
}
.register-thumb img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-top: 6px;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  line-height: 17px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-size: var(--tiny-size);
}

.tooltip .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  bottom: 125%;
}
.ant-dropdown-menu-item svg {
  font-size: 15px !important;
}
.plan-and-action-button-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  background: var(--bg);
  border-radius: 15px;
  padding: 0px 20px;
  padding-right: unset;
  box-shadow: 0px 3px 1px -2px #e3e3e3;
}
.ant-drawer-content-wrapper {
  /* width: 320px !important; */
}
.new-kpi-item {
  box-shadow: unset;
  padding: 10px 20px;
  border: unset;
  border-bottom: 2px solid #d3d3d31f;
  border-radius: unset;
  margin-bottom: 20px;
  min-height: unset;
}
.dashboard-footer-wrapper {
  margin-top: 30px;
  gap: 0px;
}
.thumb-and-content-kpi {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
}
.see-all {
  /* margin-top: -10px; */
  position: absolute;
  right: 18px;
  bottom: 15px;
}
.see-all a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.see-all svg,
.see-all span {
  font-size: var(--mini-size);
  color: var(--brand-color);
  font-weight: 500;
}

.see-all svg {
  font-size: 16px;
}
.header-menu-icon {
}
.ant-drawer-header.ant-drawer-header-close-only,
.ant-drawer-body {
  padding: 16px 40px !important;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.center-wrapper {
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  flex-direction: column;
}
.center-wrapper h2,
.center-wrapper p {
  text-align: center;
}
.center-wrapper h2 {
  color: var(--heading-color);
}
.center-button {
  width: max-content;
  margin: auto;
  margin-top: 20px;
}
.center-logo-wrap {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 2px solid #d3d3d326;
  padding-bottom: 20px;
}
.site-bot-iframe-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  margin: auto;
}
.overlay-header {
  position: absolute;
  top: 1px;
  width: 75%;
  left: 0px;
  height: 60px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: unset;
  box-shadow: unset;
}
.overlay-header.landing {
  width: 358px;
  background: #039083 !important;
}
.not-found-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  height: 80%;
}
.not-found-image span {
  color: #949ba54d;
  font-size: 14px;
}
.error-img {
  width: 100px;
  margin-bottom: 5px;
}

.chatbot-step-title {
  margin-bottom: 30px;
}
.chatbot-step-title h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
}
.chatbot-step-title p {
  font-size: 16px;
}
.ant-btn-primary {
  background: var(--brand-color) !important;
  /* padding: 10px 45px !important; */
  height: unset;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--brand-color);
  font-family: var(--poppins);
}
.ant-btn-default {
  padding: 10px 45px !important;
  height: unset;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
  border-radius: 5px;
  margin-left: unset !important;
}
.ant-btn-default:hover {
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
}

/* default */
.ant-steps-item-title,
.ant-steps-icon {
  font-weight: 600;
}
/* .ant-steps-item-title::after {
  height: 3px !important;
  top: -20px !important;
} */

/* active */
/* .ant-steps-item-active .ant-steps-item-icon {
  background: var(--brand-color) !important;
  font-size: 20px;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  font-weight: 600;
}
.ant-steps-item-title {
  line-height: 25px !important;
  font-family: var(--poppins);
  font-weight: 500;
}
.ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
  font-weight: 600;
  color: var(--brand-color) !important;
}

/* finish */
.ant-steps-item-process .ant-steps-item-active {
  background: var(--brand-color) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background: var(--brand-color) !important;
}
.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  font-weight: 600;
}
.ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title {
  font-weight: 600;
  color: var(--brand-color) !important;
}
.ant-steps-item-finish .ant-steps-item-title::after {
  background-color: var(--brand-color) !important;
  top: -20px !important;
}
.ant-steps-item-title {
  padding-inline-end: unset !important;
}
.delete-icon {
  color: #607d8b !important;
  font-size: 21px !important;
}

/* center the steps title */
/* .ant-steps-item.ant-steps-item-wait .ant-steps-item-container,
.ant-steps-item-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
} */
.ant-steps-item-tail {
}
.ant-steps-item-tail::after {
}
.remove-scroll {
  overflow: unset;
}
.prompt-group {
  margin-bottom: 10px !important;
}
.prompt-group input {
  padding: 10px;
  background: white;
  box-shadow: 0px 1px 5px -1px #d3d3d3a1;
  border: 1px solid var(--border-color);
  font-size: var(--mini-size);
}
.prompt-group label {
  font-size: var(--mini-size);
  font-weight: 400;
  margin-bottom: 3px;
  width: max-content;
  font-family: var(--poppins);
}

.language-input-wrapper {
  grid-gap: 15px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  display: block;
}
.remove-language {
  background: #ff110008;
  padding: 7px;
  border-radius: 3px;
}
.css-b62m3t-container {
  width: 350px;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--brand-color) !important;
  border-color: var(--brand-color) !important;
  font-family: var(--poppins);
}
.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 4px !important;
}
.ant-steps .ant-steps-item-tail::after {
  height: 4px !important;
}
.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 3px 15px !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--brand-color) !important;
  height: 5px !important;
}
/* 
.ant-select-selector {
  height: 45px !important;
}
.ant-select-selector .ant-select-selection-search {
}
.ant-select-arrow {
  height: 15px !important;
  margin-top: unset;
}
.ant-select-selection-item {
  text-align: left !important;
  font-size: 17px;
  line-height: 15px;
  padding-left: 10px;
} */

.add-another-language {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  grid-gap: 5px;
  cursor: pointer;
}
.add-another-language svg,
.add-another-language p {
  color: var(--brand-color);
}
.add-another-language p {
  font-weight: 500;
}
/* .css-13cymwt-control {
  min-height: 50px !important;
} */
.sync-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin-top: 40px;
}
.sync-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  border-bottom: 2px solid #d3d3d352;
  padding-bottom: 20px;
  flex-direction: column;
  width: 100%;
}
.sync-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  width: 100%;
}
.sync-item img {
  width: 30px;
}
.sync-item p {
  font-weight: 500;
  color: black;
  font-size: 16px;
}
.sync-item span {
}
.sync-input-wrap {
  width: 100%;
}
.create-chatbot-final-step {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 40px;
}
.create-chatbot-final-container::after {
  right: 700px;
}
.remove-line-container::after {
  content: unset;
}
.create-chatbot-final-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.create-chatbot-final-content {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  height: 100%;
  width: 100%;
}
.create-chatbot-final-content h2 {
}
.create-chatbot-final-content p {
}
.create-chatbot-final-content button {
  padding: 13px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}
.ant-switch-inner {
  /* background: var(--brand-color) !important; */
}

.ant-switch.ant-switch-checked {
  background: var(--brand-color) !important;
}
.ant-switch {
  min-width: 60px;
  height: 30px;
}

/* horizontal */
/* .ant-steps-label-horizontal {
  display: unset !important;
  position: relative;
  z-index: 1;
}
.ant-steps-label-horizontal::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: var(--brand-color);
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 10px;
} */
.ant-steps-finish-icon svg {
  color: white !important;
  margin-top: 3px;
}

.promp-item {
  /* height: 48px; */
  margin-bottom: unset;
  background: white;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  /* overflow: hidden; */
  /* transition: all 0.3s ease-in-out; */
}
.promp-item.active {
  height: 100%;
  overflow: unset;
}
.promp-item .uploaded-file-icon-and-name {
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.prompt-icon-text-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
}
.prompt-icon-text-wrap svg,
.prompt-icon-text-wrap span {
  color: #000000;
  font-weight: 500;
  font-size: var(--p-size);
}
.prompt-icon-text-wrap svg {
  font-size: 35px;
}
.preview-btn {
  background: #b7b7b759 !important;
  border: 1px solid #e6e6e6;
  box-shadow: unset;
}
.prompt-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prompt-content {
  padding: 5px;
  border-top: 1px solid #d3d3d354;
  padding-top: 5px;
  width: 100%;
}
.prompt-content p {
  text-align: left;
  font-size: 15px;
}
.prompt-item.active {
  background: white;
  padding: 5px;
}

/* Styles for the prompt items */
.prompt-item {
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.prompt-item .prompt-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prompt-item .prompt-content {
  display: none; /* Initially hide the prompt content */
}

.prompt-item.active .prompt-content {
  display: block; /* Display the prompt content when active */
}
.chevron {
  transition: all 0.3s ease-in-out;
}
.chevron.active {
  transform: rotate(180deg);
}
.bold-lan {
  font-weight: 600;
  color: #000;
}
.sync-input-btn {
  top: 20px;
}
.fetch-link-item {
  flex-direction: row;
}
.error-message {
  color: red !important;
  margin-top: 5px;
}
.ap-content {
  padding: 4px 0px;
}
.ap-content form,
.apearance-logo-wrapper {
  padding-top: 15px;
}
.ap-content .form-group input,
.ap-content .form-group textarea {
  background: white !important;
  border: 1px solid var(--border-color) !important;
  padding: 15px;
  font-size: var(--p-size);
}
.ap-content .form-group label,
.ap-content .form-group label {
  font-weight: 400;
  font-size: var(--mini-size);
  color: black;
  margin-bottom: 4px;
}
.req {
  color: red;
}
.ap-item {
}
.ap-wrapper {
  background: #fbfbfb !important;
  padding: 10px 0px;
  border-top: unset;
  border-bottom: 2px solid var(--border-color);
}
.ap-title {
}
.chatbot-upload-file {
  background: white;
  margin-top: 10px;
  width: 500px;
}

.file-text-preview {
  text-align: left;
  font-size: 16px;
  line-height: 25px;
}
.create-chatbot-preview-wrapper {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.uploaded-logo-wrap {
  width: 150px;
  height: 150px;
  margin-top: 20px;
}
.upload-cb-wrapper {
  overflow: hidden;
}
.img-icon {
  width: 40px;
  object-fit: contain;
}
.button-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
}
.button-outline {
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
  background: var(--white-color);
}
.language-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000bf;
  inset: 0px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.language-mask-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 7px;
}
.language-mask-wrap p {
  color: #fff;
  margin-bottom: 10px;
}
.language-mask-wrap li {
  color: black;
  font-weight: 500;
  cursor: pointer;
  background: white;
  padding: 9px 10px;
  border-radius: 5px;
  min-width: 170px;
  font-size: var(--mini-size);
}
.css-qbdosj-Input input {
  box-shadow: unset !important;
  border: unset !important;
}
.prompt-item-group {
  border-bottom: 1px solid #d3d3d354;
  padding-bottom: 8px;
  padding-top: 10px;
}
.prompt-item-group:last-child {
  border-bottom: unset;
  padding-bottom: unset;
  overflow-x: auto;
}
.prompt-item-group p {
  color: #000;
  font-weight: 600;
  font-size: var(--p-size);
  width: fit-content;
}
.prompt-item-group h3 {
  font-size: var(--tiny-size);
  font-weight: 500;
  color: #777777;
}
.mask-blur {
  filter: blur(3px);
}

/* update chatbot */
.update-chatbot-container {
  margin-top: 40px;
}

/* tabs */
.ant-tabs-nav-wrap {
  width: 300px;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background: var(--brand-color);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
.ant-tabs-tab {
  border: 1px solid lightgray !important;
  border-right: unset !important;
  /* border-bottom: unset !important; */
  margin: unset !important;
  padding: 16px !important;
  font-family: var(--poppins);
}

.ant-tabs-tab:last-of-type {
  border-bottom: 1px solid lightgray !important;
}

.ant-tabs-tab-btn {
  font-size: var(--p-size);
  font-weight: 500;
  color: black;
}
.ant-tabs-tab-btn span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  font-family: var(--poppins);
}
.ant-tabs-tab-btn span svg {
  color: #000000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span svg {
  color: white !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background: var(--brand-color) !important;
}
.update-tab-item {
  padding-left: unset !important;
  border: 1px solid lightgray !important;
}
.update-tab-item span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  font-size: var(--mini-size);
}
.update-tab-item svg {
  color: #000000;
}

.preview-title h2 {
  font-size: 25px;
  line-height: 40px;
}
.remove-after-line::after {
  content: unset;
  position: unset;
}
.update-grid-gap {
  grid-gap: 10px;
}

.remove-padding {
  padding-right: 30px;
}
.update-upload-file {
  width: 100%;
}
.update-change-btn {
  margin: -50px 0px 0px auto;
  font-size: var(--mini-size);
}
.lan-save-btn {
  margin-top: -30px;
}
.retrain-badge {
  color: var(--brand-color);
  font-weight: 600;
  /* margin-right: 5px; */
}

/* antd dropdown */
#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

.upload-avatar-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  align-items: flex-start;
  margin-top: 20px;
}
.upload-ava-title {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  text-align: left;
  margin-bottom: 12px;
}
.avatar-logo-wrap {
  width: 60px;
  height: 60px;
  background: var(--brand-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-logo-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.avatar-logo-wrap svg {
  color: white;
  font-size: 30px;
}
.custom-avatar-wrapper {
  display: grid;
  grid-template-columns: 1fr 100%;
  grid-gap: 20px;
}
/* .radio-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
} */
.avatar-item {
  width: 90%;
}
.avatar-profile {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin: auto;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}
.avatar-profile.selected {
  border: 4px solid var(--brand-color);
  box-shadow: 0px 0px 6px 2px #00968847;
}
.landing-right-action-btn-wrap {
  grid-gap: 15px;
}
.create-steps-btn-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.chatbot-iframe-send-message-btn {
  border: unset;
  background: var(--brand-color);
  color: gray;
  border-radius: 8px;
  font-size: 17px;
  /* position: absolute; */
  /* top: 5px;
  bottom: 5px; */
  padding: 0px;
  width: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  grid-gap: 7px;
  text-align: center;
  overflow: hidden;
  right: 5px;
}

/* avatar scrollbar width */
.avatar-template-wrap::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* avatar scrollbar Track */
.avatar-template-wrap::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* avatar scrollbar Handle */
.avatar-template-wrap::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* avatar scrollbar Handle on hover */
.avatar-template-wrap::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.avatar-logo-wrap.blank {
  background: unset !important;
}

/* responsive for iframe chatbot */
.iframe-container {
  border: none;
  background-color: transparent;
  height: 100vh;
  width: 100%;
  z-index: 999999999;
}

/* avatar scrollbar width */
.bot-message-wrapper-iframe::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* avatar scrollbar Track */
.bot-message-wrapper-iframe::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* avatar scrollbar Handle */
.bot-message-wrapper-iframe::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* avatar scrollbar Handle on hover */
.bot-message-wrapper-iframe::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.three-dot-icon svg {
  font-size: 20px !important;
}

/* iframe */

/* avatar scrollbar width */
iframe::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* avatar scrollbar Track */
iframe::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* avatar scrollbar Handle */
iframe::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* avatar scrollbar Handle on hover */
iframe::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
/* iframe */

.all-bot-list {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.ant-switch .ant-switch-handle {
  position: absolute;
  top: 4px;
  inset-inline-start: 2px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
  left: 6px;
  border-radius: 43px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 25px) !important;
}
.icon-and-text-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  min-width: 140px;
}
.whatsapp-sync-label {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
}
.whatsapp-sync-label svg,
.whatsapp-sync-label span {
  color: var(--brand-color);
  font-weight: 500;
}
.whatsapp-sync-label span {
  font-size: 16px;
}
.whatsapp-sync-label svg {
  font-size: 17px;
}

/* Marketplace page
==================== */
.marketplace-container {
  /* position: relative; */
}
.marketplace-title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.marketplace-title .form-group {
  width: max-content;
}
.marketplace-title h2 {
  font-size: var(--title-size);
  font-weight: 600;
}
.marketplace-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
}
.marketplace-item {
  background: #d8dee8;
  padding: 30px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-gap: 15px;
  position: relative;
}
.marketplace-item h2 {
  font-size: var(--p-size);
  font-weight: 500;
  line-height: 30px;
}
.marketplace-btn {
  background: var(--brand-color);
  border-radius: 5px;
  color: var(--white-color);
  padding: 10px 15px;
  font-size: var(--mini-size);
  font-weight: 500;
  transition: all 0.3s ease-in;
}
.marketplace-btn:hover {
  background: var(--brand-color-bold);
}
.marketplace-btn.disabled {
  background: gray;
  cursor: not-allowed;
}
.marketplace-item-thumb {
  width: auto;
  height: 60px;
}
.marketplace-item-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.button-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.marketplace-details-item {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: unset;
  width: 800px;
  padding: 40px;
  grid-gap: 15px;
  margin-bottom: 30px;
}
.marketplace-details-item p,
.marketplace-popup-wrap p {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}
.marketplace-details-title h2 {
  font-size: var(--title-size);
  font-weight: 700;
}

/* popup */
.marketplace-popup-container {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000047;
  z-index: 999;
}
.marketplace-popup-wrap {
  background: white;
  width: 600px;
  height: 300px;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 20px;
  position: relative;
}
.cancel-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  color: gray;
  font-size: 32px;
  height: unset;
}
.marketplace-popup-wrap h3 {
  font-size: var(--title-size);
}
.add-file-or-link-btn-wrap {
  display: flex;
  justify-content: right;
  margin: -60px 0 20px 0;
  min-height: 50px;
}

.guide-content-wrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}
.guide-slider {
  /* margin-bottom: 40px; */
}
.guide-content-wrap h3 {
  font-size: 20px;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 10px !important;
}
.swiper-pagination-bullet-active {
  background: var(--brand-color);
  box-shadow: 0px 0px 3px 0px gray;
}
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}
.code-wrap {
}
.code-container {
}
.code-wrapper {
  overflow: hidden;
  white-space: pre-line;
  max-height: 500px; /* Adjust the max-height as needed */
  padding: 10px;
  background-color: #f4f4f5;
  border: 1px solid #f4f4f5;
  border-radius: 4px;
  margin: 30px 0px;
  margin-top: unset;
}
code {
  color: #000;
  font-size: var(--p-size);
}
.code-content {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  flex-direction: column;
  text-align: center;
  max-width: 90%;
  margin: auto;
  margin-bottom: 20px;
}

.code-content h2 {
  font-size: var(--title-size);
  font-weight: 600;
}
.code-content p {
  font-size: var(--mini-size);
  line-height: 24px;
}
.code-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
}
.bot-copy-wrap {
  width: 150px;
}
.code-btn {
  font-size: var(--p-size);
}
.code-btn svg {
  color: var(--white-color);
  font-size: 17px;
}
.code-btn-outline svg {
  color: var(--brand-color);
}
.code-modal-wrapper {
  width: 700px;
}
.code-modal-wrapper.whatsapp-bot {
  height: auto;
  overflow-y: auto;
  padding: 30px;
}
.guide-slider h3 {
  font-size: 20px;
  margin: 10px 0px;
}

/* test css */
.calendly-bot-section {
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 999;
}

.slick-prev,
.slick-next {
  color: black;
}
.slick-prev {
  left: -30px;
}
.slick-next {
  right: -30px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: gray !important;
}

.marketplace-btn.outline {
  margin-top: 10px;
  background: #006bff;
  margin-bottom: 10px;
}
.calender-board {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 20px 0px;
}
.calender-board h6 {
  text-align: center;
}
.calender-board p {
  /* text-align: center; */
  font-size: 12px;
  /* color: grey; */
}

.meeting-time-wrap {
  display: flex;
  grid-gap: 3px;
}
.meeting-time-wrap p {
  font-size: 10px;
  color: white;
  line-height: 14px;
}

.meeting-active-block {
}

.meeting-active-title {
  color: white;
  font-size: 13px;
  font-weight: 500;
}

.calendar-meeting-details-wrap {
}
.calendar-meeting-details-wrap h3 {
  margin-bottom: 15px;
  margin-top: 5px;
}
.calendar-meeting-details-content {
}
.calendar-meeting-details-content p,
.meeting-location {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.meeting-location span {
  text-transform: capitalize;
}
.calendar-meeting-details {
  margin-bottom: 30px;
  font-size: var(--title-size);
  font-weight: 400;
  color: black;
}
.calendar-meeting-details p {
  display: unset;
  font-size: var(--title-size);
  font-weight: 400;
  color: black;
}
.calendar-meeting-details-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  margin-top: 20px;
  background: #0096881f;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  color: var(--brand-color);
  padding: 6px 25px;
  box-shadow: 0px 3px 1px 0px #0096886e;
  transition: all 0.3s ease-in;
}
.calendar-meeting-details-btn:hover {
  box-shadow: 0px 3px 1px 0px var(--brand-color);
}
.calendar-meeting-details-btn img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.calendar-meeting-details-dates {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
/* time slot */
.time-slot-container {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}
.time-slots {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.time-slot {
  border: 1px dotted var(--brand-color);
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  color: var(--brand-color);
  cursor: pointer;
  /* transition: all 0.1s ease-in; */
}
.time-slot:hover {
  background: var(--brand-color);
  color: white;
}
.time-slot.selected {
  background: var(--brand-color);
  color: white;
}
.time-slot.disabled {
  background: lightgray;
  color: gray;
  border: transparent;
}
.time-slot-title {
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
  text-align: center;
  margin-bottom: 20px;
}
.time-slot-title h2 {
  font-size: var(--title-size);
  line-height: 35px;
}
.time-slot-title p {
  font-size: var(--mini-size);
}
.submit-calendar-btn {
  width: 100% !important;
  margin-bottom: 10px;
}
.bot-calendar-widget {
  margin-bottom: 30px;
}
.custom-date-filter-result {
  font-size: 14px;
}
.copy-link-wrap {
  margin-top: 10px;
}
.copy-link-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.copy-link-group input {
  border-top-right-radius: unset;
  border-top-right-radius: unset;
  font-size: 14px;
}
.copy-link-group input:focus {
  border: unset;
  outline: unset;
}
.copy-site-link-btn {
  width: 155px;
  padding: 8px;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  background: #607d8b;
  border: 1px solid transparent;
  grid-gap: 5px;
}
.copy-site-link-btn:hover {
  transform: unset;
}
.copy-site-link-btn svg {
  color: white !important;
  font-size: var(--title-size);
}

.calendar-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-with-google-calendar-btn {
  padding: 10px;
}
.calendar-container {
  padding-bottom: 30px;
  height: 650px;
  margin-top: 10px;
}
.pricing-section {
}
.title-and-filter-result-wrap {
  flex-direction: column;
  align-items: flex-start;
}
.bot-date-filter-dropdown {
}
.bot-date-filter-dropdown.css-b62m3t-container {
  min-width: 160px;
}
.dashboard-parent-tab-wrapper {
  margin-bottom: 30px;
}
.dashboard-parent-tab-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e6e6;
  width: max-content;
  padding: 6px;
  border-radius: 35px;
}
.dashboard-parent-header-tab {
  padding: 7px 15px;
  color: gray;
  font-size: 14px;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
  border-radius: 5px;
}
.dashboard-parent-header-tab.react-tabs__tab--selected {
  background: var(--brand-color);
  outline: none;
  color: white;
  border-radius: 35px;
}
.custom-accordion-title {
  font-size: 17px;
  font-weight: 600;
  align-items: center;
  color: var(--accent-color);
  /* text-transform: capitalize; */
  font-family: 'Poppins';
}
.custom-accordion-title p {
  font-size: var(--mini-size);
  font-family: 'Poppins';
}
.custom-accordion-icon {
  font-size: 22px !important;
}
.marketplace-btn.dangar {
  background: firebrick;
}
.marketplace-modal table tr,
.marketplace-modal table tr td {
  padding: 10px !important;
  font-family: var(--font-poppins);
}

.marketplace-modal .ant-modal-title {
  font-size: 20px;
  font-family: var(--font-poppins);
}
.marketplace-modal .ant-modal-header {
  margin-bottom: 20px;
}
.select-all-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.select-all-links span {
  color: gray;
  font-weight: 400;
  font-family: var(--font-poppins);
  font-size: 13px;
}
.fetched-link-file-icon-wrap {
  flex-direction: row;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--brand-color) !important;
  border-color: var(--brand-color) !important;
}
.whatsapp-dropdown {
  background: var(--brand-color);
  color: white;
  height: 40px;
  border-radius: 10px;
}
.whatsapp-dropdown .ant-select-selector {
  background: var(--brand-color) !important;
  color: white;
}
.ant-select-selection-item {
  font-family: var(--font-poppins);
  font-weight: 500;
}
.whatsapp-dropdown .ant-select-arrow svg {
  color: white !important;
}
.whatsapp-action-wrap {
  display: flex;
  flex-direction: flex-start;
  grid-gap: 10px;
  align-items: center;
}
.whatsapp-action-wrap p {
  color: gray;
}

/* price radio */
.price-radio-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 10px;
  font-family: var(--font-poppins);
}
.price-radio {
  width: max-content;
  padding: 12px 30px;
  border-radius: 5px;
  border: 1px dotted var(--brand-color);
  max-height: 200px;
  background: white;
  font-family: var(--font-poppins);
  position: relative;
  padding-left: 20px;
  padding-right: 50px;
}
.price-tag-label {
  font-weight: 600;
  font-size: 15px;
}
.price-tag-value {
  font-size: 14px;
  font-weight: 400;
}
.price-radio-group .ant-radio-wrapper-checked.price-radio {
  background: var(--brand-color);
}
.price-radio .ant-radio-checked {
}
.price-radio .ant-radio-checked .ant-radio-inner {
  background: var(--white-color) !important;
  border-color: var(--white-color) !important;
}
.price-radio .ant-radio-checked .ant-radio-inner::after {
  background: var(--brand-color) !important;
}
.price-radio-group .ant-radio-wrapper-checked.price-radio .price-tag-label,
.price-radio-group .ant-radio-wrapper-checked.price-radio .price-tag-value {
  color: white;
}
.popover-wrap {
  position: absolute;
  top: 7px;
  right: 7px;
}
.popover-wrap svg {
  color: lightgray;
  font-size: 22px;
}
.ant-popover-content p {
  font-size: 14px;
}

.ant-popover-content {
  width: 265px;
}

.subscribe-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
button:hover {
}
.subscribe-btn.unsubscribe {
  color: firebrick;
  background: #ff000014;
}

.billing-kpi-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
}
.billing-kpi-item {
  border: 1px solid #d3d3d369;
  padding: 20px;
  border-radius: 5px;
}
.billing-kpi-item p {
  font-size: var(--p-size);
  font-weight: 600;
  color: var(--brand-color);
}
.billing-kpi-item p:last-child {
  font-size: var(--mini-size);
  color: var(--gray-color);
  font-weight: 500;
}
.billing-kpi-item svg {
  color: var(--gray-color);
}

.addon-billing-table-wrap {
  margin-top: 30px;
}
.addon-billing-table-wrap h3 {
  font-size: var(--title-size);
  margin-bottom: 10px;
  color: var(--h-color);
  font-size: var(--p-size);
  font-weight: 600;
}

.addon-billing-table-wrap p {
}
.addon-billing-table-wrap table {
  border: 1px solid var(--border-color);
  border-radius: 3px;
}
.addon-billing-table-wrap table tr th {
  border: 1px solid var(--border-color);
  color: var(--h-color);
  font-weight: 600;
  font-size: var(--mini-size);
  padding: 15px;
}
.addon-billing-table-wrap tbody tr td {
  border: 1px solid var(--border-color);
  color: var(--gray-color);
  font-size: var(--mini-size);
  font-weight: 400;
  padding: 10px 15px;
}
.addon-billing-table-wrap thead {
}
.dashboard-pricing-plan-title {
  margin-top: 40px;
}
.not-found {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  margin-bottom: 20px;
}
.table-admin-badge {
  color: var(--brand-color);
  font-weight: 500;
  font-size: var(--tiny-size);
  background: #0096880f;
  border-radius: 20px;
  padding: 5px 10px;
  line-height: 0px;
}
.table-admin-badge.user {
  color: var(--purpleColor);
  background: #c211a112;
}
.table-admin-badge.subscriber {
  color: var(--orange-color);
  background: #fd346e12;
}
.balance-badge {
  font-weight: 500;
  color: var(--brand-color);
}
.img-fluid {
  width: 37px;
  background: white;
  border-radius: 30px;
  margin-right: 5px;
}
.prompt-badge {
  background: #0096882b;
  color: var(--brand-color) !important;
  border-radius: 20px;
  padding: 0px 10px;
  font-size: var(--tiny-size) !important;
  margin-top: 5px;
}
.static-details {
  margin-top: 10px;
}
.page-details-content-block a {
  font-weight: 500;
  color: var(--brand-color);
  border-bottom: 1px dotted lightgray;
}

.page-details-content-block ul {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-top: 10px;
}
.page-details-content-block ul li {
  font-size: var(--p-size);
}
.page-details-content-block p,
.page-details-content-block ul li {
  color: #000000;
}
.privacy-check {
  margin-bottom: 10px;
}
.privacy-check a {
  font-weight: 500;
  color: var(--brand-color);
  border-bottom: 1px dotted lightgray;
  font-family: var(--poppins);
  font-size: var(--tiny-size);
}
.privacy-check span,
.privacy-check label {
  font-family: var(--poppins);
  font-size: var(--tiny-size);
}
.privacy-checkbox .ant-checkbox {
  border: 1px solid transparent;
}
.privacy-checkbox.active .ant-checkbox {
  border: 1px solid red;
}

/* chatbot type */
.chatbot-type-section {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d32e;
  border-radius: 5px;
}
.chatbot-type-wrapper {
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.chatbot-type-content {
}
.chatbot-type-content h2 {
  font-size: var(--heading-two);
  margin-bottom: 10px;
}
.chatbot-type-action {
  grid-gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbot-type-btn {
}
.whatsapp-btn {
  background: #25d366;
}

.whatsapp-btn:hover {
  background: green;
}
.wa-integration-section {
  border: 1px solid #d3d3d32e;
  border-radius: 5px;
  padding: 40px 20px;
}
.wa-integration-wrapper {
}
.wa-form,
.wa-integration-wrapper .chatbot-type-content {
  width: 610px;
  margin: auto;
}

.wa-integration-wrapper .chatbot-type-content {
  text-align: center;
  margin-bottom: 10px;
}
.waba-id-btn {
  background: firebrick;
}
.waba-id-btn:hover {
  background: #a21717 !important;
}
.wa-details-action {
  align-items: flex-end;
}

.marketplace-popup-wrap p {
}
.marketplace-popup-wrap ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.marketplace-popup-wrap ul li {
  list-style: disc;
  margin-left: 18px;
  color: #000000;
  font-size: var(--mini-size);
}
.view-details-popup {
  height: auto;
  padding: 35px;
}
.view-details-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 10px;
  text-align: left;
}
.icon-img {
  width: 30px;
  object-fit: contain;
  border-radius: 2px;
}
.select-language-item .ant-select-selector {
  border: unset !important;
  padding: unset !important;
}
.bot-type-img {
  width: 20px;
}
.bot-type-img-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.list-bot-content a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.disabled-bot {
}
.disabled-bot .list-bot-logo.no-bot {
  background: gray !important;
}
.disabled-bot .form-button {
  background: gray !important;
}

.disabled-bot .all-bot-action-btn.button-outline {
  border: 2px solid gray !important;

  background: white !important;
}
.disabled-bot .all-bot-action-btn.button-outline svg,
.disabled-bot .all-bot-action-btn.button-outline span {
  color: gray !important;
}
.disabled-bot .retrain-badge {
  color: gray !important;
}
.wa-check-wrap {
}
.wa-check-wrap p {
}
.wa-check-box {
  margin-top: 10px;
}
.ant-radio-wrapper {
  font-family: var(--poppins);
}
.wa-check-box .ant-radio-checked .ant-radio-inner {
  background-color: var(--brand-color) !important;
}
.inline-radio-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
}
.wa-check-box .ant-radio-wrapper {
  font-weight: 500;
  font-size: var(--mini-size);
}
.select-language-item .ant-select-arrow {
  left: 70px !important;
}
.bot-dropdown {
  width: 200px;
  z-index: 999;
}
.css-1dimb5e-singleValue {
  font-weight: 500;
}

/* connect bot with google calendars */
.gc-container {
}
.gc-connect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  margin-top: 5px;
}
.gc-item {
  width: min-content;
}
.gc-chatbots {
}
.gc-google-calendars {
}

.gc-chatbots .ant-select-selector,
.gc-google-calendars .ant-select-selector {
  width: 230px !important;
  height: 42px !important;
  font-family: var(--poppins);
}
.gc-google-calendars .ant-select-selector {
  min-height: 42px !important;
  height: auto !important;
}
.gc-chatbots,
.gc-google-calendars {
  width: 230px !important;
  height: 42px !important;
  font-family: var(--poppins);
}
.gc-chatbots svg,
.gc-google-calendars svg {
  font-size: 13px !important;
}
.ant-select-item-option-content,
.ant-progress-text {
  font-family: var(--poppins);
}
.progress-wrap {
  width: 420px;
}
.ant-progress-bg {
  background: var(--brand-color) !important;
}
.progress-wrap svg {
  color: var(--brand-color) !important;
}

/* dashboard tab header second edition */
.dashboard-parent-tab-header.second-edition {
  border-radius: 3px;
  padding: unset;
}
.dashboard-parent-header-tab.second-edition {
  border-radius: 5px;
  min-width: 185px;
  height: 42px;
  padding: unset;
}

.dashboard-parent-header-tab.second-edition.react-tabs__tab--selected {
  border-radius: 5px;
  box-shadow: var(--shadow);
}
.dashboard-parent-header-tab.second-edition.react-tabs__tab--selected svg {
  color: white;
  font-size: 20px;
}
.dashboard-parent-tab-header.second-edition {
  border-radius: 5px;
  padding: unset;
}

.rbc-toolbar {
}
.rbc-month-view {
  border-radius: 8px;
}
.gc-btn-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.disconnect-btn {
  background: firebrick !important;
  color: white;
  border: 1px solid transparent;
}

.rbc-toolbar .rbc-btn-group:last-child button:last-child {
  display: none;
}

/* antd table */
.ant-picker-content thead tr th,
.ant-picker-content tbody tr td {
  padding: 8px !important;
  text-align: unset;
}
.ant-radio-group label,
.ant-btn {
  font-family: var(--poppins);
}

.ant-picker-calendar-header
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: var(--brand-color) !important;
  border-color: var(--brand-color) !important;
}

/* .ant-picker-calendar-header .ant-picker-cell-inner.ant-picker-calendar-date {
  background: var(--brand-color) !important;
  color: #fff !important;
} */

.ant-picker-calendar-header .anticon.anticon-down.ant-select-suffix {
  width: var(--mini-size) !important;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
}
.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
  background: var(--brand-color) !important;
  color: #fff !important;
}

.connected-gc-chatbots {
  padding: unset;
  position: relative;
}
.bot-dropdown input:focus {
  border: unset !important;
}
.export-xlsx-btn {
  background: var(--white-color);
  cursor: pointer;
  border: 1px solid var(--brand-color-light);
  box-shadow: unset;
}

.export-xlsx-btn p {
  color: var(--brand-color);
  transition: all 0.3s ease-in-out;
}
.export-xlsx-btn:hover,
.export-xlsx-btn:hover p,
.export-xlsx-btn:hover svg {
  color: var(--brand-color-bold) !important;
}
.dashboard-bot-content {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
}

.bot-content-details svg {
  color: #d9d9d9;
}
.bot-content-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.bot-content-details a {
  font-weight: 400;
}
.bot-content-details a:hover {
  color: var(--brand-color);
}
.kpi-right-item-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--brand-color);
}
.kpi-right-item-wrap {
  grid-gap: 10px;
}

.chart-items {
  margin: 20px 0px;
}
.ctr-box {
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
}
.ctr-content {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  flex-direction: column;
  text-align: center;
}
.ctr-content h3:hover {
  color: var(--brand-color);
}
.ctr-content svg {
  font-size: 30px;
  color: var(--gray-color);
}
.chart-header-wrapper {
  justify-content: space-between;
  align-items: center;
}

.bot-date-filter-dropdown .css-13cymwt-control {
  border: 1px solid var(--brand-color-light);
}
.dashboard-statics-wrapper {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px; */
}

.dashboard-bot-item {
  background: var(--brand-color-light);
}
.dashboard-bot-item p,
.dashboard-bot-item span,
.dashboard-bot-item svg {
  color: var(--brand-color);
}
/* Assuming your custom color is red */
.dashboard-kpi-item.react-tabs__tab--selected:focus-visible {
  outline-color: transparent !important;
  /* border-bottom: 2px solid var(--brand-color); */
}

/* blog section */
.blog-landing-page {
  margin: 50px 0px;
}
.featured-section {
}
.featured-blog-wrapper {
  display: grid;
  grid-template-columns: 5fr 6fr;
  grid-gap: 25px;
  padding: 30px;
  background: var(--bg-color);
  border-radius: 5px;
}
.featured-blog-thumb {
  width: 100%;
  height: 340px;
}
.featured-blog-thumb img {
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
}
.featured-blog-content {
}
.featured-blog-content h2 {
  font-size: var(--heading-size);
  line-height: 45px;
  font-weight: 700;
  margin: 10px 0px;
}
.featured-blog-content p {
  color: #000000d6;
}
.featured-blog-content a {
}
.category-badge,
.author-badge {
  color: var(--brand-color);
  font-weight: 500;
}
.category-badge {
  background: var(--brand-color-light);
  padding: 5px 13px;
  border-radius: 25px;
  font-size: var(--mini-size);
}
.author-badge {
  margin-right: 15px;
}
.featured-excerpt {
  margin-top: 10px;
}
.latest-blog-section {
  margin: 50px 0px;
}
.latest-blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.latest-blog-wrapper {
  display: flex;
  flex-direction: column;
  padding: unset;
  background: unset;
}
.latest-blog-thumb {
  height: 225px;
}
.latest-blog-content .category-badge {
  font-size: 13px;
}
.latest-blog-content h2 {
  font-size: 23px;
  line-height: 35px;
  font-weight: 600;
}
.latest-excerpt {
}
.blog-title {
  margin-bottom: 20px;
}
.blog-title h2 {
  font-size: var(--heading-size);
}
.single-main-container {
  margin: 50px 0px;
}
.single-title {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
}

.single-container {
  display: grid;
  grid-template-columns: 8fr 3fr;
  grid-gap: 35px;
  position: relative;
}
.single-wrapper {
}
.single-content p {
  margin-bottom: 15px;
  color: var(--black);
}
.single-sidebar {
  position: sticky;
  top: 0px;
  width: 100%;
  height: auto;
}
.single-sidebar img {
  width: 100%;
  border-radius: 3px;
  position: sticky;
  top: 100px;
  box-shadow: var(--shadow);
}
.single-thumb {
  width: 100%;
  height: 400px;
  margin: 20px 0px;
}
.single-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.single-wrapper .single-cat-badge {
  font-size: var(--mini-size);
}
.single-cat-badge {
  margin-bottom: 10px;
}

.author-wrapper {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-gap: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 30px;
}
.author-profile {
  width: 110px;
  height: 110px;
}
.author-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.author-content {
}
.author-content p {
  font-size: var(--mini-size);
}
.author-content h1 {
  font-size: var(--title-size);
}
.author-social {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.author-social svg {
  font-size: 18px;
}
.author-social svg:hover {
  color: var(--brand-color);
}

.help-center-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  margin: 20px 0px;
}
.help-center-box {
  border-radius: 5px;
}
.help-center-video {
  width: 100%;
}
.help-center-video iframe {
  border-radius: 5px;
  width: 100%;
  height: 250px;
}
.help-center-content {
  margin-bottom: 10px;
}

.help-center-content h2 {
  font-size: var(--title-size);
}
.title h2 {
  font-size: var(--heading-one);
}
.copy-modal-tabs {
  margin-top: 15px;
}
.copy-modal-tabs .ant-tabs-tab {
  padding: 9px 15px !important;
  border: unset !important;
}
.copy-modal-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-size: var(--mini-size) !important;
  font-weight: 500;
}
.copy-modal-tabs .ant-tabs .ant-tabs-ink-bar {
  background: unset !important;
}
.copy-modal-tab-input {
  margin-top: unset;
  margin-bottom: 20px;
}

/* new landing page */
/* hero section */
.new-hero-section {
  /* background: #f5f6fa; */
}
.new-hero-wrap {
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
}
.new-hero-wrap .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  text-align: center;
}
.new-hero-wrap .landing-hero-action {
  width: 100%;
  margin: 20px auto;
}
.new-hero-wrap .landing-hero-thumb {
  width: unset;
  height: unset;
}
.new-hero-wrap .landing-hero-thumb iframe {
  border-radius: 10px;
  margin: auto;
}

/* marquee */
.marquee-section {
  padding: 40px 0px;
}

.marquee-img {
  width: auto;
  height: auto;
  object-fit: contain;
}
.landing-title {
  margin: 20px 0px;
}
.landing-title h3 {
  padding: 20px 0px;
  text-align: center;
  font-size: var(--heading-size);
  text-transform: capitalize;
}

/* process section */
.process-section {
  padding: 40px 0px;
  background: #f5f6fa;
}
.process-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.process-box {
  background: white;
  border-radius: 5px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  padding: 20px;
  flex-direction: column;
  text-align: center;
}
.process-box p {
  color: #000000;
}
.process-box svg {
  font-size: 18px;
  width: 55px;
  background: #00968812;
  border-radius: 50%;
  padding: 13px;
}
/* testimonial */
.testimonial-section {
  padding: 40px 0px;
  background-image: url(https://lp.simplified.com/607f32d6309ca31d63c6724d/65d890d31cb64788c9ca28ca_Ellipse%20164.svg);
  background-position: 50% 13%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  position: relative;
}

.testimonial-wrapper {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; */
}
.testimonial-box {
  background: white;
  border-radius: 5px;
  box-shadow: 3px 3px 0px 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  flex-direction: column;
  padding: 20px;
  border: 2px solid black;
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.tes-profile {
  background: white;
  /* border-radius: 5px; */
  /* box-shadow: var(--shadow); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding-bottom: 5px;
}
.tes-profile img {
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.tes-profile-content p {
  line-height: 20px;
  font-size: var(--mini-size);
}
.tes-profile-content h3 {
  line-height: 24px;
  font-size: var(--p-size);
}
.tes-review {
  margin-top: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tes-review svg {
  color: orangered;
  font-size: 15px;
  margin-right: 2px;
}
.tes-content {
}

/* left side flex */
.left-side-flex-section {
  padding: 40px 0px;
}
.left-side-flex-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: center;
}
.left-side-flex-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 25px;
}
.left-side-flex-content p {
}
.left-side-flex-content h2 {
  font-size: 50px;
  text-align: flex-start;
  line-height: 70px;
  text-transform: capitalize;
}
.left-side-flex-content button {
}
.left-side-flex-thumb {
}
.left-side-flex-video {
  border-radius: 12px;
}
.ant-dropdown.ant-dropdown-placement-bottomRight {
  z-index: 9999999999 !important;
}
.loading-img {
  object-fit: cover !important;
  background: white;
}

.clear-default-format-selectbox input {
  width: 100% !important;
  border: unset !important;
  min-width: unset !important;
}

/* slick slider */
.slick-slider-with-gap .slick-list {
  margin: 0 -10px;
}

.slick-slider-with-gap .slick-slide > div {
  margin: 0 10px;
}

.dashboard-kpi-item {
  height: 100%;
  display: flex;
  justify-content: center;
  min-height: 120px;
}

.thumb-and-content-kpi {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.slick-arrow {
  width: 30px;
  height: 30px;
  background-color: var(--brand-color-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--brand-color);
  z-index: 1;
  padding: 5px;
  box-shadow: var(--shadow);
}

.slick-arrow:hover,
.slick-arrow:focus {
  background-color: var(--brand-color);
  color: white !important;
}

.slick-prev {
  left: -15px;
}

.slick-next {
  right: -15px;
}

.slick-prev:before,
.slick-next:before {
  content: '';
}

/* and style */
.ant-select-selection-item {
  margin-right: 10px;
}

.form-control {
  height: 45px !important; /* Increase height */
  padding: 10px; /* Optional: adjust padding */
  font-size: 16px; /* Optional: increase font size */
}
