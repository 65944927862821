@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('../src/style/Dashboard.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-family: 'Poppins', sans-serif;
  }
  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
}

/* text editor descriptions  
=========================== */
.descriptions {
  @apply p-6 bg-white;
}
.descriptions table {
  @apply mb-4 rounded-md border border-slate-300 p-2;
}

.descriptions th {
  @apply border border-slate-300 p-2;
}

.descriptions td {
  @apply border border-slate-300 p-2;
}

.descriptions tr {
  @apply border border-slate-300 p-2;
}

.descriptions th {
  @apply border border-slate-300 p-2;
}
.descriptions h1 {
  @apply mb-5 text-3xl font-bold text-rose-700;
}
.descriptions h2 {
  @apply mb-5 text-2xl font-bold;
}
.descriptions h3 {
  @apply mb-5 text-xl font-bold;
}
.descriptions h4 {
  @apply mb-4 text-lg font-bold;
}
.descriptions h5 {
  @apply mb-4 text-base font-bold;
}
.descriptions h6 {
  @apply mb-3 text-xs font-bold;
}
.descriptions p {
  @apply mb-4 text-base leading-relaxed text-gray-700;
}
.descriptions ul {
  @apply mb-4 ml-1 list-inside list-disc flex flex-col;
}
.descriptions ol {
  @apply mb-4 list-inside list-decimal;
}
.descriptions li {
  @apply relative inline-flex pl-5 text-base;
}
.descriptions li:before {
  @apply absolute left-0 top-2 h-3 w-3 rounded bg-slate-300 content-[''];
}

.descriptions blockquote {
  @apply mb-4 rounded-md border-l-2 border-slate-400 bg-green-100 p-5 pl-7;
}
.descriptions code {
  @apply mb-4 rounded-md bg-slate-100 p-1;
}
.descriptions hr {
  @apply mb-4 border-t-2 border-slate-300;
}
.descriptions img {
  @apply mb-4 rounded-md;
}
.descriptions video {
  @apply mb-4 rounded-md;
}
.descriptions iframe {
  @apply mb-4 rounded-md;
}
.descriptions a {
  @apply text-brand-color underline;
}
.descriptions .ProseMirror {
  @apply p-4;
}
.descriptions .ProseMirror:focus {
  @apply outline-none;
}
.descriptions ul li::marker {
  color: red;
  font-size: 1.5em;
}

.html-content a {
  @apply text-brand-color hover:text-brand-color-bold;
}

.ant-picker-ok .ant-btn {
  @apply !p-1 !px-3 text-sm text-white
}
.css-13cymwt-control {
  @apply !border-slate-200 !border-[1px]
}
.rc-virtual-list-holder {
  /* @apply !max-h-[100px] */
}