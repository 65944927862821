/* This is for only desktop version */
@media only screen and (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
  .mobile-sidebar-cross {
    display: none;
  }
  .mobile-version-kpi-wrapper {
    display: none;
  }
  .landing-header-toggle {
    display: none;
  }
  .burger-menu-icon {
  }
}

/* @media screen and (-webkit-min-device-pixel-ratio:0) { 
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: #eee;
  }
} */

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/* This is for mobile or small devices version */
@media only screen and (max-width: 768px) {
  .mobile-version-kpi-wrapper {
    display: none;
  }
  .mobile-hide {
    display: none;
  }
  .breadcrumbs {
    display: none;
  }
  header {
    padding: 0px 10px;
  }
  /* .container {
    padding: 0px;
    max-width: 95%;
  } */

  aside.sidebar {
    width: 0px;
  }
  aside.sidebar.active {
    width: 80% !important;
  }
  .featured-status-wrapper,
  .chart-wrapper {
    flex-direction: column;
  }
  .chart-wrapper {
    margin-top: 15px;
    grid-gap: 10px;
    display: none;
  }
  .date-filter {
    width: 100%;
  }
  .p-20 {
    padding: 0px;
  }
  .analytics-wrapper {
    grid-template-columns: 1fr;
  }
  .table-container {
    margin: 0px;
  }
  .table-filter-container {
    flex-direction: column;
    display: none;
  }
  .table-filter {
    flex-direction: column;
  }
  .create-user-form {
    max-width: 95%;
  }
  .pagination-wrapper {
    margin-top: unset;
  }
  .integrate-container {
    padding: 0;
  }
  .integrate-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .settings-container {
  }
  .settings-wrapper form {
    /* padding: 15px; */
    display: flex;
    flex-direction: column-reverse;
  }
  main {
    width: 100%;
    margin: 0px;
  }
  main.active {
    width: 100%;
    margin-left: 0px;
  }
  .body-wrapper {
    width: 100%;
  }
  .search-box input {
    height: 38px;
    width: 230px;
  }
  .breadcrumbs-and-filter {
    justify-content: flex-end;
  }
  .featured-status-wrapper {
    margin-top: 10px;
    grid-gap: 10px;
    overflow-x: scroll;
  }
  .search-box {
    display: none;
  }
  .chart-svg-wrapper {
    height: 170px;
    overflow: hidden;
  }
  aside.sidebar.mobile-active {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .not-found-content h1 {
    font-size: 120px;
    line-height: 160px;
  }
  .not-found-wrapper {
    max-width: 95%;
  }
  /* admin */
  .chat-body-sidebar {
    /* display: none; */
    min-width: unset;
    min-height: unset;
    max-height: unset;
    height: 100%;
    max-width: 100%;
    padding: 10px;
  }
  .admin-header {
    /* position: fixed; */
    width: 100%;
    flex-direction: row;
    min-width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    z-index: 1;
    flex-direction: column;
    padding: unset;
  }
  .admin-page-wrapper {
    height: unset;
    flex-direction: column;
  }
  .admin-header ul:last-child {
    display: none;
  }
  .admin-header ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    grid-gap: 0px;
    flex-direction: revert;
    width: 100%;
    position: fixed;
    align-items: center;
    bottom: 0px;
    background: white;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px lightgray;
    height: 60px;
  }
  .recent-chat {
    height: unset;
    max-height: unset;
  }
  .chatbox-message-body {
    display: none;
  }
  .recent-chat-wrap {
    padding-bottom: 30px;
  }
  .chat-title-big h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .admin-header ul li svg {
    font-size: 20px;
    color: var(--gray);
  }
  .admin-header ul li img {
    width: 21px;
  }
  .chat-body-wrapper {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .chatbox-message-body.active {
    display: block;
    /* position: absolute;
    inset: 0; */
    background: white;
  }
  .chatbox-header.active {
    position: sticky;
    width: 100%;
    z-index: 999999999;
    background: white;
    box-shadow: 0px 0px 5px 0px lightgray;
    top: 60px;
  }
  .chatbot-creator {
    display: none;
  }
  .chatbox-header-right-options {
    grid-gap: 10px;
  }
  .chatbox-header-icon svg {
    font-size: 18px;
  }
  .chatbox-creator p {
    font-size: 10px;
    color: gray;
  }
  .chatbot-creator img {
    width: 15px;
    border-radius: 2px;
  }
  .mobile-header-burger-menu {
    cursor: pointer;
  }
  .mobile-header-burger-menu img {
    width: 30px;
    cursor: pointer;
  }
  .chatbox-footer {
    position: fixed;
    bottom: 0px;
    background: white;
    width: 100%;
  }
  .chat-conversation-wrapper {
    padding: 0px 5px;
  }
  .message-body li {
    grid-gap: 5px;
    margin-bottom: 20px;
  }
  .chatbox-conversation {
    height: 100%;
    max-height: 100%;
  }
  .chatbox {
    position: fixed;
    inset: 0;
  }
  ul.message-body {
    padding: 80px 0px;
    padding-bottom: 0px;
    padding-top: 60px;
  }

  .analytics-guide {
    height: auto;
    padding: 30px;
    width: 100%;
  }
  header.active {
    width: 100%;
    background: white;
    box-shadow: 0px 0px 3px 0px lightgray;
    z-index: 999;
  }
  .billing-wrapper {
    padding-top: 0px;
  }
  .chatbox-close-icon {
    font-size: 25px !important;
  }
  .message-day-slot-item {
    padding: 1px 10px;
  }
  .chatbot-widget-header-right {
    position: static;
  }
  .chatbot-widget {
    transition: unset;
  }
  .search-model.active {
    right: 20px;
    /* left: 0; */
    width: 90%;
    margin: auto;
  }
  .search-model input {
    width: 100%;
  }
  .register-wrapper form {
    width: 100%;
    padding: 30px 15px;
    padding-bottom: 20px;
  }
  /* user profile */
  .user-profile-section {
    margin: 5px;
  }
  .user-profile-body form {
    max-width: 100%;
    padding: 15px;
  }
  .user-profile-body {
    margin-top: 0px;
    padding: 12px;
  }
  .user-profile-header-thumb {
    width: 100px;
    height: 100px;
  }
  .user-profile-header-wrapper {
    padding: 10px;
    background: #b4e6ff;
  }
  .user-join-date p {
    display: none;
  }
  .chatbot-widget-wrapper {
    position: unset;
    width: 100%;
    height: 100%;
  }
  .chatbot-widget.active {
    height: 100%;
    width: 100%;
    border-radius: unset;
    position: fixed;
    inset: 0;
    z-index: 9999;
  }
  .chat-conversation-wrapper {
    height: 100%;
    min-height: unset;
    max-height: unset;
    padding-bottom: 0px;
  }
  .chatbot-footer {
    /* position: absolute;
    bottom: 0px; */
    width: 100%;
    background: white;
    padding: 10px;
    /* height: 65px; */
    z-index: 99;
  }
  .chatbot-footer form {
    width: 100%;
    margin: auto;
  }
  .chatbot-recipient-avatar {
    position: relative;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 5px 0px lightgray;
    border-radius: 50%;
  }
  /* .chatbot-recipient-avatar img {
    width: 100%;
    height: 100%;
  } */
  .chatbot-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background: #fcfcfc !important; */
    padding: 10px;
    border-radius: unset;
    overflow: hidden;
    border-bottom: 2px solid #d3d3d31f;
  }
  .chatlogs-chatbot-widget-header {
    background: unset;
  }
  .chatbot-iframe-widget-header {
    border-radius: unset;
  }
  .chatbot-widget-recipient-details h3 {
    font-weight: 500;
    line-height: 13px;
    font-size: 14px;
    color: gray;
  }
  .chatbot-mini-preview-wrap {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-top-left-radius: 20px;
    padding: 8px 30px;
  }
  aside.sidebar ul li a {
    justify-content: flex-start;
  }
  .featured-status-thumb {
    display: none;
  }
  .featured-status-item {
    padding: 20px;
    width: 200px;
    height: 150px;
  }
  .blank {
    display: none;
  }
  .date-item input {
    font-size: 13px;
  }
  .dashboard-kpi-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 10px;
  }
  .dashboard-new-design-header {
    /* display: none; */
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 14px;
    margin-bottom: 0px;
    padding-top: unset;
  }
  .chatlog-profile-card {
    display: none;
  }
  .chatbot-widget-recipient-details p {
    display: none;
  }
  .kpi-item-header {
    grid-gap: 5px;
  }
  .chatbox-conversation-form {
    width: 100%;
  }
  .details-sidebar {
    right: -100px;
    width: 100%;
    padding: 15px;
  }
  .refresh-icon svg {
    fill: #607d8b;
    font-size: 25px !important;
    border-radius: unset;
    width: unset;
    height: unset;
  }
  .bot-message-wrapper {
    height: 45vh !important;
  }
  .bulk-message-wrapper {
    grid-template-columns: 1fr;
  }
  .bulk-media-thumb {
    width: 100%;
  }
  .bulk-message-container {
    margin: unset;
    background: white;
    padding: 10px;
  }
  .bulk-message-header {
    grid-gap: 10px;
    margin-top: 0px;
  }
  .bulk-message-header h3 {
    font-size: 16px;
  }
  .table-wrap-bulk {
    height: 300px;
    overflow-y: auto;
  }
  .language-select-box {
    color: gray;
  }
  .chatbot-widget-options svg,
  .chatbot-widget-minimize svg,
  .delete-chat-icon svg {
    fill: gray;
    color: gray;
  }
  .header-profile {
    grid-gap: 5px;
  }
  .header-profile p {
    font-size: 13px;
    line-height: 18px;
  }
  .chatbot-message-body {
    padding: 5px !important;
  }
  .landing-header-wrapper {
    max-width: 100%;
    border-radius: unset;
    padding: 10px 20px;
  }
  .landing-step-ss {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 25px;
    flex-direction: column;
  }
  .video-demo-wrapper {
    max-width: 100%;
    margin: auto;
  }
  .footer-menu .landing-page-menu {
    flex-direction: column;
    align-items: center;
  }
  .footer-menu-wrap li::before {
    display: none;
  }
  .footer-logo img {
    margin-left: unset;
  }
  .landing-page-menu {
    display: flex;
    grid-gap: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .landing-page-menu li {
    width: max-content;
  }
  .accordion-container {
    max-width: 95%;
    margin: 20px auto;
  }
  .landing-hero-thumb {
    display: none;
  }
  .landing-hero-wrap {
    grid-template-columns: 1fr;
  }
  .call-to-action-wrapper .landing-hero-action button {
    width: fit-content;
  }
  .call-to-action-wrapper {
    padding: 25px;
    max-width: 95%;
  }
  .pricing-wrapper {
    flex-direction: column;
  }
  .pricing-container {
    margin: 50px auto;
  }
  .landing-container {
    max-width: 90%;
    grid-template-columns: 1fr;
  }
  .register-thumb {
    display: none;
  }
  .landing-header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .landing-header-menu.show-menu {
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 5px;
  }
  .menu-list-text-item {
    /* display: none; */
  }
  .menu-list-text-item.active {
    display: block;
  }
  .menu-list-text-item.active a {
    font-size: 16px;
  }
  .landing-header-right {
    /* display: none; */
  }
  .landing-header-right.active {
    display: block;
  }
  .landing-header-wrapper.menu-open {
    justify-content: flex-start;
    flex-direction: column;
    height: 100vh;
  }
  .sign-in-btn,
  .landing-hero-action-btn {
    font-size: 14px;
    padding: 7px 12px;
  }
  .spacing {
    display: none;
  }
  .action-header-wrap {
    width: 100%;
    justify-content: flex-end;
  }
  .hero-content h1 {
    font-size: 42px;
    max-width: 95%;
  }
  .bot-message-wrapper-iframe {
    padding-bottom: 0px;
    /* min-height: 65vh; */
    max-height: unset;
    /* height: unset; */
  }
  /* iframe */
  .musk-body-and-suggest-prompt-wrap {
  }

  /* avatar scrollbar width */
  .bot-message-wrapper-iframe::-webkit-scrollbar {
    width: 7px;
    overflow-y: hidden;
  }

  /* avatar scrollbar Track */
  .bot-message-wrapper-iframe::-webkit-scrollbar-track {
    background: #f1f1f193;
    overflow-y: hidden;
  }

  /* avatar scrollbar Handle */
  .bot-message-wrapper-iframe::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.486);
    border-radius: 10px;
    overflow-y: hidden;
  }

  /* avatar scrollbar Handle on hover */
  .bot-message-wrapper-iframe::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }
  /* iframe */
  .chatbot-iframe-left p {
    display: unset;
  }
  .register-container {
    padding-top: unset;
  }
  .already-have-account {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-button {
    width: 100%;
  }
  .login-with-google-icon {
    margin-bottom: unset;
  }
  .header-nav {
    display: none;
  }
  .upgrate-btn {
    display: none;
  }
  .setting-panel {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 25px;
    padding: 15px;
  }
  .mobile-sidebar-cross {
    position: absolute;
    right: 15px;
    top: 70px;
    font-size: 35px;
    color: red;
    cursor: pointer;
    z-index: 9999999;
  }
  .mobile-sidebar-wrapper.active {
    width: 100%;
    background: #00000069;
    height: 100vh;
    position: fixed;
    z-index: 999;
  }
  .mobile-sidebar-wrapper .sidebar.active {
  }
  .header-logo {
    width: 35px;
    height: 35px;
  }
  aside.sidebar ul {
    margin-top: 70px;
  }
  .sidebar .form-button {
    width: max-content;
  }
  .active-link svg,
  .active-link span {
    width: max-content;
  }
  .bot-upgrate-alart {
    grid-gap: 10px;
    bottom: 20px;
    right: 10px;
    left: 10px;
  }
  .bot-upgrate-alart a {
    grid-gap: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  .list-bot-option-wrap a {
    width: 100%;
  }
  .dot-badge {
    display: none;
  }
  .table-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .table-title {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    margin-bottom: 10px;
  }

  .list-of-the-bot-item {
    padding: 20px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .list-bot-option-wrap {
    grid-gap: 14px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .bot-action-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 10px;
    border-top: 1px solid #d3d3d34a;
    width: 100%;
    padding-top: 10px;
  }
  .list-bot-logo {
    width: 40px;
    height: 40px;
  }
  .list-bot-logo svg {
    font-size: 25px;
  }
  .list-of-the-bot-wrapper {
    
  }
  .demo-video-tut-icon {
    font-size: 30px;
  }

  .demo-video-tut {
    border: 1px solid #d3d3d34a;
    /* border-left: unset; */
    /* border-right: unset; */
    padding: 10px 15px;
    align-items: flex-start;
    text-align: inherit;
    border-radius: 5px;
  }
  .chatbot-config-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    padding: 15px 0px;
    padding-top: 20px !important;
  }
  .demo-video-modal-wrapper iframe {
    border-radius: 5px;
    width: 100%;
  }
  .demo-video-modal-wrapper {
    width: 95%;
    padding: 10px;
  }
  .list-bot-profile {
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #d3d3d34a;
    padding-bottom: 10px;
  }
  .landing-header-wrapper.active-sticky {
    padding: unset;
  }
  .accordion-title {
    padding: 10px 0px;
  }
  .desktop-header-menu-wrapper {
    width: 100%;
    max-width: 100%;
    margin: unset;
    border-radius: unset;
    justify-content: center;
    align-items: center;
  }
  .desktop-header-menu-wrapper.active-sticky {
    padding: 10px 20px;
  }
  .mobile-header-left {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .mobile-header-left .menu-list-text-item {
    border-bottom: 1px solid #d3d3d373;
    padding-bottom: 10px;
    width: 100%;
  }
  .mobile-header-left .menu-list-text-item:last-child {
    border-bottom: unset;
  }
  .mobile-header-menu-wrapper {
    background: white;
    height: 100vh;
    padding: 30px;
    position: fixed;
    width: 100%;
    border-top: 1px solid lightgray;
    visibility: hidden;
    top: 55px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .mobile-header-menu-wrapper.active {
    visibility: visible;
    opacity: 1;
    top: 66px;
    z-index: 9999;
  }
  .mobile-header-right {
    margin-top: 20px;
  }
  .alart-error {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: unset;
    margin-top: unset;
    border: 1px solid firebrick;
    padding: 20px 10px;
    margin-bottom: 15px;
    grid-gap: 10px;
  }
  .alart-content h2 {
    margin-bottom: 10px;
  }
  .alart-content h2,
  .alart-content p {
    text-align: center;
  }
  .alart-content p {
    line-height: 20px;
  }
  .plan-and-action-button-wrap {
    display: none;
  }
  .ant-drawer-header.ant-drawer-header-close-only,
  .ant-drawer-body {
    padding: 20px 25px !important;
  }
  .flex-label-icon span {
    width: max-content;
  }
  .three-dot-icon svg {
    font-size: 20px !important;
  }
  /* .toggle-bot-icon {
    margin: 15px;
    height: 60px;
    width: 60px;
  } */
  .toggle-bot-icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin: 30px;
    position: fixed;
    right: 0px;
    bottom: 0px;
  }

  /* config bot  */
  .update-chatbot-container {
    flex-direction: column;
    margin-top: 15px;
  }
  .ant-tabs-nav-wrap {
    width: 100%;
  }
  .update-change-btn {
    padding: 10px 30px;
    margin: unset;
    margin-top: 10px;
    width: max-content;
  }
  .config-bot-widget {
    width: unset !important;
    height: unset !important;
    position: unset !important;
  }
  .create-chatbot-final-wrapper {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 10px;
  }
  .create-chatbot-final-step {
    grid-template-columns: 1fr;
  }
  .create-chatbot-file-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .download-btn-wrap {
    width: 100%;
    padding: 10px;
  }
  .create-chatbot-container {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    background: #fbfbfb;
    padding: 20px;
    position: relative;
  }
  .create-chatbot-file-body {
    min-height: 100%;
    max-height: 100%;
    padding-right: unset;
  }
  .chatbot-upload-file {
    width: 100%;
  }
  .css-b62m3t-container {
    width: 100% !important;
  }
  .remove-scroll {
    padding-right: unset;
  }
  .form-group label {
    flex-wrap: wrap;
    line-height: 17px;
  }
  .prompt-group {
    margin-bottom: 15px !important;
  }
  .create-title {
    margin-bottom: 3px;
  }
  .prompt-group input {
    padding: 10px;
    margin-top: 5px;
  }
  .ap-content .form-group input,
  .ap-content .form-group textarea {
    padding: 10px;
  }
  .remove-padding {
    padding-right: 10px;
  }
  .custom-avatar-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  .chatbot-upload-image-wrap {
    width: 100% !important;
  }
  .chatbot-upload-content-wrap h3 {
    font-size: 18px;
    text-align: center;
  }
  .embadded-btn {
    justify-content: center;
    align-items: center;
    position: unset;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  .prompt-wrapper {
    padding-right: unset;
  }
  .preview-title h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .uploaded-file-icon-and-name {
    padding: 0px 5px;
  }
  .ant-tabs-tab {
    padding: 10px !important;
  }
  .marketplace-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .marketplace-title h2 {
    font-size: 20px;
  }
  .marketplace-details-item {
    width: 100%;
    padding: 20px;
  }
  .marketplace-popup-wrap {
    width: 95%;
    height: auto;
    padding: 40px;
    grid-gap: 15px;
  }
  .all-bot-list {
    margin-bottom: 10px;
  }
  .dashboard.all-bot-list {
    margin-bottom: 10px;
    width: 100%;
  }
  .add-file-or-link-btn-wrap {
    margin: unset;
    min-height: auto;
  }
  .sub-heading {
    font-size: 12px;
    width: max-content;
  }
  .code-btn {
    font-size: 13px;
    padding: 10px 0px;
  }
  .bot-copy-wrap {
    width: 100%;
  }
  .ant-popover-content {
    width: 98%;
  }
  .price-radio-group {
    flex-direction: column;
    width: 100%;
  }
  .price-radio {
    padding: 5px 10px;
    width: 100%;
  }
  .subscribe-btn {
    position: unset;
  }
  .dashboard-title-wrap {
    margin: 5px 0px;
  }
  .copy-site-link-btn {
    width: 140px !important;
    padding: 8px;
    font-size: 13px;
  }
  .copy-site-link-btn svg {
    font-size: 15px;
  }
  .code-content {
    max-width: 100%;
  }
  .code-container {
    padding: 10px;
    margin-top: 0px;
  }
  .code-wrapper {
    margin: 10px 0px;
  }
  .chatbot-step-title {
    margin-bottom: 20px;
  }
  .marketplace-btn {
    padding: 10px 15px;
    font-size: 12px;
  }
  .marketplace-item {
    padding: 15px 20px;
    border-radius: 5px;
    grid-gap: 10px;
  }
  .marketplace-status {
    font-size: 12px;
    padding: 0px 10px;
    right: 15px;
    top: 15px;
  }
  .marketplace-item-thumb {
    height: 45px;
  }
  .header-section {
    margin-bottom: 20px;
  }
  .marketplace-title {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-gap: 10px;
  }
  .marketplace-title .form-group {
    width: 100%;
  }
  .react-tabs {
    width: 100%;
    flex-direction: column;
    grid-gap: 10px;
  }
  .landing-title h2 {
    font-size: 32px;
  }
  .landing-title p {
    max-width: 90%;
  }
  .landing-title {
    margin-top: unset;
  }
  .billing-kpi-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 10px 0px;
  }
  .billing-kpi-item {
    border: 1px solid #d3d3d369;
    padding: 15px;
    border-radius: 5px;
  }
  .dashboard-header-group .all-bot-list {
    margin-bottom: unset;
  }
  .chart-header-wrapper {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
  }
  .export-xlsx-btn {
    background: var(--white-color);
    cursor: pointer;
    border: 1px solid var(--brand-color-light);
    box-shadow: unset;
    padding: 10px;
  }
  .new-kpi-item {
    padding: 15px 15px;
  }
  .bot-content-details {
    flex-wrap: wrap;
    grid-gap: 0px;
  }
  .dashboard-parent-tab-header {
    width: 100%;
  }
  .dashboard-parent-tab-header.second-edition {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .dashboard-header-group {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
  .code-modal-wrapper.whatsapp-bot {
    height: auto;
    padding: 15px 10px;
  }
  .thumb-and-content-kpi {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 10px;
  }
  .single-container {
    grid-template-columns: 1fr;
  }
  .latest-blog-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }
  .breadcrumb li {
    font-size: 13px;
    display: none;
  }
  .author-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    box-shadow: var(--shadow);
  }
  .featured-blog-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    padding: 15px;
    background: var(--bg-color);
    border-radius: 5px;
  }
  .featured-blog-thumb {
    width: 100%;
    height: 200px;
  }
  .help-center-wrapper {
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }
  .help-center-video iframe {
    height: 180px;
  }
  .title h2 {
    font-size: var(--title-size);
  }
  .dashboard-parent-header-tab.second-edition {
    height: 40px;
    width: 100%;
    min-width: unset;
  }
  .details-thumb-and-content {
    flex-direction: row;
  }
  .dashboard-kpi-item {
    padding: 15px;
    min-height: 170px;
  }
  .kpi-item-icon svg {
    font-size: 25px;
  }
  .kpi-item-icon {
    height: 50px;
    width: 50px;
  }
  .dashboard-dropdown .bot-date-filter-dropdown {
    min-width: 130px;
  }
  .export-xlsx-btn p {
    font-size: var(--tiny-size);
    font-weight: 500;
  }
  .error-img {
    width: 70px;
  }
  .list-bot-logo img {
    width: 40px;
    height: 40px;
  }
  .ctr-content {
    grid-gap: 10px;
  }
  .ctr-content p {
    font-size: var(--mini-size);
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
  .chatbot-type-action {
    flex-direction: column;
  }
  .process-wrapper {
    grid-template-columns: 1fr;
  }
  .add-link-model {
    width: 90%;
    height: 95vh;
    overflow-y: scroll;
}
.addlink-modal-cancel-icon {
  /* float: right; */
  /* margin: -25px; */
  /* font-size: 30px; */
  color: grey !important;
  /* transition: all 0.3s ease-in-out; */
  position: fixed;
  top: 65px;
  right: 85px;
}
.dashboard-header-right {
  width: 100%;
}
.kpi-right-item-wrap {
  flex-direction: column;
  width: 100%;
}
.dashboard-dropdown {
  width: 100%;
}
}
